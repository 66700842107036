import React,{useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AdminProducts from "../../Components/Admin/AdminProducts";
import AdminProductSalesReport from "../../Components/Admin/AdminProductSalesReport";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import Pagination from "../../Components/Utilities/Pagination";
//import ViewProductAdminHook from "../../hook/admin/ViewProductAdminHook";
import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";

const AdminProductSalesReportPage = () => {
    const dispatch = useDispatch();
    const [from, setFrom] = useState(()=>new Date().toISOString().split('T')[0]);
    const [to, setTo] = useState(()=>new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    
    const [
        items,
        handleSearchDate,
        totalPrice1,
        totalPrice2,
        zeroFlag,
        setZeroFlag,
        settingsRes,
        pagination,
        getPage,
        // loading
    ] = ViewSalesProductAdminHook({from,to,loading,setLoading});

    if (pagination) var pageCount = pagination;
    else pageCount = 1;

    // console.log('items in parent',items&&items[0]);
    // console.log('pageCount',pageCount);

    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminProductSalesReport from={from} setFrom={setFrom} to={to} setTo={setTo} loading={loading} setLoading={setLoading}     />
                {pageCount > 0 ? (
          <Pagination pageCount={pageCount} onPress={getPage} />
        ) : null}
            </div>
        </div>

    );
};

export default AdminProductSalesReportPage;
