import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import avatar from "../../Images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { getOneSlider, updateSlider } from "../../redux/actions/sliderActions";

const AdminEditSliderHook = (id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const run = async () => {
      await dispatch(getOneSlider(id));
    };
    run();
  }, []);

  const item = useSelector((state) => state.sliderReducer.oneSlider);

  // const [image, setimage] = useState("");
  // const [selectedFile, setSelectedFile] = useState(null);

  const [title, settitle] = useState("");
  const [desc, setdesc] = useState("");
  const [link, setlink] = useState("");

  // show color picker
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [link2, setLink2] = useState("");

  useEffect(() => {
    if (item) {
        settitle(item.title);
        setdesc(item.description);
        setColor(item.color);
        setlink(item.link);
        setLink2(item.link2);
        if (item.images) setImages(item.images);
    }
  }, [item]);
  const onChangeLink = (e) => {
    setlink(e.target.value);
  };

  const onChangeLink2 = (e) => {
    setLink2(e.target.value);
  };

  const onChangeTitle = (e) => {
    settitle(e.target.value);
  };
  const onChangeDesc = (e) => {
    setdesc(e.target.value);
  };

  // const onImageChange = (event) => {
  //     if (event.target.files && event.target.files[0])
  //         setimage(URL.createObjectURL(event.target.files[0]));
  //     setSelectedFile(event.target.files[0]);
  // };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  const handleChaneComplete = (color) => {
    setColor(color.hex);
    setShowPicker(!showPicker);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (images === []) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }

    let itemImages = [];
    //convert array of base 64 image to file
    Array.from(Array(Object.keys(images).length).keys()).map((item, index) => {
      if (images[index].length <= 1000) {
        // console.log("dd");
        convertURLtoFile(images[index]).then((val) => itemImages.push(val));
      } else {
        // console.log("ee");
        itemImages.push(dataURLtoFile(images[index], Math.random() + ".png"));
      }
    });


    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", desc);
    formData.append("color", color);
    formData.append("link", link);
    formData.append("link2", link2);
        itemImages.map((item) => formData.append("images", item));

    setCheck(true);
      setLoading(true);
      await dispatch(updateSlider(id, formData));
      setLoading(false);
      setCheck(false)
  };

  const sliderRes = useSelector((state) => state.sliderReducer.updateSlider);
  useEffect(() => {
    if (loading === false) {
      if (sliderRes) {
        if (sliderRes.status === 200) {
          notify("تم التعديل بنجاح", "success");
        } else {
          notify("فشل في عملية الاضافه", "error");
        }
      }
    }
  }, [loading]);

  return [
    title,
    desc,
    showPicker,
    color,
    handleChaneComplete,
    handleSubmit,
    onChangeTitle,
    onChangeDesc,
    onChangePicker,
    check,
    link,
    onChangeLink,
    images,
    setImages,
    link2,
    onChangeLink2
  ];
};

export default AdminEditSliderHook;
