import React from "react";
import AdminAddCategory from "../../Components/Admin/AdminAddCategory";
import { Col, Container, Row } from "react-bootstrap";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import AdminPolicy from "../../Components/Admin/AdminPolicy";
import SideBar from "../../Components/Admin/SideBar";

const AdminPolicyPage = () => {
  return (

    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminPolicy />
      </div>
    </div>

  );
};

export default AdminPolicyPage;
