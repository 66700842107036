import React from 'react'
import { FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md'

export default function AdminSearch({searchTerm,setSearchTerm}) {
    const { t } = useTranslation();
    // console.log('searchTerm');
    // console.log(searchTerm);
  return (
    <div className="d-flex my-3" style={{height:'fit-content'}}>
      <FormControl
      value={searchTerm}
     onChange={(e)=>setSearchTerm(e.target.value)}
     placeholder={t("search here")}
     style={{width: '83%'}}
     className="custom-input"  />

      <button 
      title="الغاء الفلتر"  
      className="" 
      style={{ background: 'none',border:'none' }}
      onClick={(e)=>setSearchTerm('')}
      ><MdCancel style={{color:'#32373c', width:'100%' ,height:'27px' }} /> 
      </button>

      </div>
  )
}
