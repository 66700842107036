import { ADD_TO_FAV, REMOVE_FROM_FAV, USER_FAVORITE } from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetDataToken } from "../../hooks/useGetData";

export const addToFavorite = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/favorite/add/website`, body);

    dispatch({
      type: ADD_TO_FAV,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ADD_TO_FAV,
      payload: error,
    });
  }
};

export const removeFromFavorite = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(
      `/api/v1/favorite/delete/website`,
      body
    );

    dispatch({
      type: REMOVE_FROM_FAV,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_FROM_FAV,
      payload: error,
    });
  }
};

export const favoriteByUserId = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/favorite/${id}`);

    dispatch({
      type: USER_FAVORITE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: USER_FAVORITE,
      payload: error,
    });
  }
};
