import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import VendorSideBar from "../../Components/Vendor/VendorSideBar";
import UserProfile from '../../Components/User/UserProfile';



const VendorProfilePage = () => {
    return    (
        <Container >
        <Row className='py-3'>
            <Col sm="3" xs="2" md="2">
            <VendorSideBar/>
            </Col>
            <Col sm="9" xs="10" md="10">
        <UserProfile/>
            </Col>
        </Row>
    </Container>
    )

}

export default VendorProfilePage

