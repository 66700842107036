import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminEditDropShipping from "../../Components/Admin/AdminEditDropShipping";

const AdminEditDropShippingPage = () => {
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-4" style={{ width: '100%' }}>
     <AdminEditDropShipping/>
    </div>
  </div>
  )
};

export default AdminEditDropShippingPage;
