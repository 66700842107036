import { Row, Col } from "react-bootstrap";
import React from "react";
import { ToastContainer } from "react-toastify";
import AddSubCatHook from "../../hook/subcategory/AddSubCatHook";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AdminAddSubCategory = () => {
  const { id } = useParams();
  const { t } = useTranslation() ;
  const [
    img,
    nameAr,
    nameEn,
    category,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    handleChange,
    settingsRes
  ] = AddSubCatHook(id);

  return (
    <div>
    
    <Helmet>
    <title>{ `  ${t("add subcategory")}    - ${Cookies.get("i18next")==="ar"?settingsRes.data?.titleAr:settingsRes.data?.titleEn}` } </title>
    </Helmet>

      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"> {("add new subcategory")}</div>
        <Col sm="8">
          <div className="text-form pb-2"> {t("category image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="subCatAr" className="d-block mt-2 "> {t("subcategory name in arabic")} </label>
          <input
          id="subCatAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("subcategory name in arabic")}
          />
          <label htmlFor="subCatEn" className="d-block mt-2 ">{t("subcategory name in english")} </label>
          <input
          id="subCatEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("subcategory name in english")}
          />
          {/* <select
            name="category"
            id="cat"
            className="select mt-3 px-2 "
            onChange={handleChange}
          >
            <option value="0">{t("Choose main category")}  </option>
            {category.data
              ? category.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {el.nameAr}
                    </option>
                  );
                })
              : null}
          </select> */}
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
          {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminAddSubCategory;
