import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Spinner } from "react-bootstrap";
import SubCategoryCard from "./SubCategoryCard";
import LoadingSpinner from "../Utilities/LoadingSpinner";
const SubCategoryContainer = ({ category, loading }) => {
  const { t } = useTranslation()
  const colors = [
    "#FFD3E8",
    "#F4DBA5",
    "#55CFDF",
    "#FF6262",
    "#0034FF",
    "#FFD3E8",
  ];
  return (
    <Container>
      <div className="admin-content-text mt-2 ">  {t("All SubCategories")}</div>
      <Row className="my-2 d-flex justify-content-start">
        {loading === false ? (
          category ? (
            category.map((el, index) => {
              return (
                <SubCategoryCard
                  key={el._id}
                  title={Cookies.get("i18next") === 'ar' ? el.nameAr : el.nameEn}
                  img={el.image}
                  id={el._id}
                  background={colors[Math.floor(Math.random() * 5) + 1]}
                />
              );
            })
          ) : (
            <h3 className="text-center">{t("no categories")}</h3>
          )
        ) : (
          <LoadingSpinner />
        )}
      </Row>
    </Container>
  );
};

export default SubCategoryContainer;
