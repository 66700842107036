import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import ProductsResults from "../../Components/Utilities/ProductsResults";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const ShopProductPage = () => {
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    ,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    ,
    ,
    loadingSpinner,
    settingsRes
  ] = ViewSearchProductHooks();

  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  const allproduct = useSelector((state) => state.allProduct.allProductsInSearchPage);


  console.log('items',items?.length);
  console.log('pagination',pagination);

  return (
    <div >
        <Helmet>
      <title>{` ${ ' المنتجات'} ${items? '-' : ' '} ${settingsRes?.data?.titleAr}`} </title>
      {/* <meta name="description" content={item?.descriptionProduct} />
      <meta name="keywords" content={item?.keywordsProducts} /> */}
      <link rel="canonical" href={window.location.href} />
      {/* <meta name="robots" content="index, follow" />
        <title>{item?.nameAr}</title>
        <meta name="description" content={item?.descriptionProduct} />
        <meta name="keywords" content={item?.keywordsProducts} />
        <meta property="og:title" content={item?.nameAr} />
        <meta property="og:description" content={item?.descriptionProduct} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={currentUrl} /> */}
      </Helmet>
      <CategoryHeader />      
      <Container>
        <ProductsResults onClick={getProduct} title={`${result} نتيجة بحث`} />
        <Row className="d-flex">
          <Col className="d-flex" md="2" sm="2">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
              }}
            />
          </Col>
          <Col md="10" sm="10" xs="12">
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}{" "}
          </Col>
        </Row>
      {pagination&&<Pagination pageCount={pageCount} onPress={onPress} />}  
      </Container>
    </div>
  );
};

// allproduct?.numberOfPages >0 && 
export default ShopProductPage;