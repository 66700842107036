import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { editSettings, sendNotificationToAllUsers } from "../../redux/actions/settingsAcions";
import { getShippingMethods } from "../../redux/actions/shippingMethodAction";
import {
  backup,
  getAllBackups,
  deleteBackupLog,
  restoreDackupLog,
  restoreImportBackupFile
} from "../../redux/actions/backupRestoreAction";
import avatar from "../../Images/avatar.png";
import { useTranslation } from "react-i18next";
import baseURL from "../../Api/baseURL";


const AdminSettingsHook = () => {
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [phone, setPhone] = useState("");
  // const [shippingId, setShippingId] = useState("");

  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [googleAnalyticsURL, setGoogleAnalyticsURL] = useState("");

  const [showCategory, setshowCategory] = useState("");
  const [showBrand, setshowBrand] = useState("");
  const [vatNumber, setvatNumber] = useState("");
  const [vatValue, setvatValue] = useState("");
  const [commercialRegister, setcommercialRegister] = useState("");

  const [titleAr, settitleAr] = useState("");
  const [titleEn, settitleEn] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [showReviews, setShowReviews] = useState("");
  const [showFeaturedProducts, setShowFeaturedProducts] = useState("");

  const [showAddressDetail, setShowAddressDetail] = useState("");

  const [descriptionSite, setDescriptionSite] = useState("");
  const [keywordsSite, setKeywordsSite] = useState("");
  const [blockSite, setBlockSite] = useState("");
  const[blockSiteInBodyEnd,setBlockSiteInBodyEnd]=useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [img, setImg] = useState(avatar);

  const [showPicker, setShowPicker] = useState(false);

  const [colors, setColors] = useState("");

  const [seccolors, setSeccolors] = useState("");

  const [showPickerseccolors, setShowPickerseccolors] = useState(false);

  const [loadingBackup, setLoadingBackup] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const [backupId, setBackupId] = useState("");
  const [loadingDeleteBackup, setLoadingDeleteBackup] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(true);
  const [backupDowloadName, setBackupDownloadName] = useState("");
  const [restoreDataModal, setRestoreDataModal] = useState(false);
  const [restoreBackupLogName, setRestoreBackupLogName] = useState('')
  const [loadingRestoreBackupLogName, setLoadingRestoreBackupLog] = useState(true)
  const [archiveFile, setArchiveFile] = useState(null)
  const [loadingUploadFile, setLoadingUploadFile] = useState(true)
  const [loadingRestore, setLoadingRestore] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingRestoreLog, setLoadingRestoreLog] = useState(false)
  const [showNavbarCategory, setShowNavbarCategory] = useState("");
  const [googleMapLink,setGoogleMapLink] = useState("");
  const[googlePlayLink,setGooglePlayLink]=useState("");
  const[appleStoreLink,setAppleStoreLink]=useState("");
  const[cashBack,setCashBack]=useState("");
  const [hideProductDescriptionTabs,setHideProductDescriptionTabs] = useState('');
  const[twokLink,setTwokLink]=useState("");

  const[messageTitle,setMessageTitle]=useState("");
  const[messageBody,setMessageBody]=useState("");
  const[couponText,setCouponText]=useState("");

  const[whatsApp,setWhatsApp]=useState('');

  const closeRestoreDataModel = () => setRestoreDataModal(false);

  const closeDeleteModal = () => setDeleteModal(false);

  const { t } = useTranslation();

  //blockSiteInBodyEnd
  const onChangeBlockSiteInBodyEnd=(e)=>{
      setBlockSiteInBodyEnd(e.target.value);
  }

  const onChangeArchiveFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      // console.log(e.target.files[0].name)
      setArchiveFile(e.target.files[0])
    }
  }


  const onChangePickerseccolors = (e) => {
    setShowPickerseccolors(!showPickerseccolors);
  };

  const handleChaneCompleteseccolors = (color) => {
    setSeccolors(color.hex);
    setShowPickerseccolors(!showPickerseccolors);
  };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  const handleChaneComplete = (color) => {
    setColors(color.hex);
    setShowPicker(!showPicker);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  const onGooglePlayLinkChange=(e)=>{
    console.log('google play', e.target.value);
    setGooglePlayLink(e.target.value);
  };

  const onAppleStoreLinkChange=(e)=>{
    
    setAppleStoreLink(e.target.value);
  }

  


  const dispatch = useDispatch();

  const onChangeFacebook = (e) => {
    e.persist();
    setFacebook(e.target.value);
  };
  const onChangeInstagram = (e) => {
    e.persist();
    setInstagram(e.target.value);
  };
  const onChangeTwitter = (e) => {
    e.persist();
    setTwitter(e.target.value);
  };
  const onChangePhone = (e) => {
    e.persist();
    setPhone(e.target.value);
  };
  const onChangeGoogleMapLink = (e) => {
    e.persist();
    setGoogleMapLink(e.target.value)
  }
  // const onChangeShippingId = (e) => {
  //   e.persist();
  //   setShippingId(e.target.value);
  // };

  const onChangePhone2 = (e) => {
    e.persist();
    setPhone2(e.target.value);
  };
  const onChangePhone3 = (e) => {
    e.persist();
    setPhone3(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const onChangeAddress = (e) => {
    e.persist();
    setAddress(e.target.value);
  };
  const onChangeGoogleAnalyticsURL = (e) => {
    e.persist();
    setGoogleAnalyticsURL(e.target.value);
  };

  const onChangeShowCategory = (e) => {
    e.persist();
    setshowCategory(e.target.value);
  };
  const onChangeShowBrand = (e) => {
    e.persist();
    setshowBrand(e.target.value);
  };
  const onChangeVatNumber = (e) => {
    e.persist();
    setvatNumber(e.target.value);
  };
  const onChangeVatValue = (e) => {
    e.persist();
    setvatValue(e.target.value);
  };
  const onChangeCommercialRegister = (e) => {
    e.persist();
    setcommercialRegister(e.target.value);
  };

  const onChangeTitleAr = (e) => {
    e.persist();
    settitleAr(e.target.value);
  };
  const onChangeTitleEn = (e) => {
    e.persist();
    settitleEn(e.target.value);
  };

  const onChangeshowReviews = (e) => {
    e.persist();
    setShowReviews(e.target.value);
  };

  const onChangeShowFeaturedProducts = (e) => {
    e.persist();
    setShowFeaturedProducts(e.target.value);
  };

  const onChangeShowAddressDetail = (e) => {
    e.persist();
    setShowAddressDetail(e.target.value);
  };

  const onChangeDescriptionSite = (e) => {
    e.persist();
    setDescriptionSite(e.target.value);
  };
  const onChangeKeywordsSite = (e) => {
    e.persist();
    setKeywordsSite(e.target.value);
  };
  const onChangeBlockSite = (e) => {
    e.persist();
    setBlockSite(e.target.value);
  };

  const onChangeShowNavCategoryHeader = (e) => {
    e.persist();
    setShowNavbarCategory(e.target.value);
  };

  const onChangeHideProductDescriptionTabs = (e) => {
    setHideProductDescriptionTabs(e.target.value)
  }

  const onChangeCashBack=(e)=>{
    setCashBack(e.target.value);
  }

  const onChangeTwokLink=(e)=>{
    setTwokLink(e.target.value);
  }
  //twokLink


  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  // const shippingData = useSelector(
  //   (state) => state.shippingMethodReducer.shipping
  // );

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      // await dispatch(getShippingMethods());
      await dispatch(getAllBackups());
      setLoading(false);
    };
    get();
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (settingsRes && settingsRes.data) {
      // console.log('settingsRes.data')
      // console.log(settingsRes.data)

      setFacebook(settingsRes.data.facebook);
      setInstagram(settingsRes.data.instagram);
      setTwitter(settingsRes.data.twitter);
      setPhone(settingsRes.data.phone);
      // setShippingId(settingsRes.data.defaultShipping?._id);

      setPhone2(settingsRes.data.phone2);
      setPhone3(settingsRes.data.phone3);
      setEmail(settingsRes.data.email);
      setAddress(settingsRes.data.address);
      setGoogleAnalyticsURL(settingsRes.data.googleAnalyticsURL);

      setshowCategory(settingsRes.data.showCategory);
      setvatNumber(settingsRes.data.vatNumber);
      setshowBrand(settingsRes.data.showBrand);
      setvatValue(settingsRes.data.vatValue);
      setcommercialRegister(settingsRes.data.commercialRegister);

      settitleAr(settingsRes.data.titleAr);
      settitleEn(settingsRes.data.titleEn);
      setShowReviews(settingsRes.data.showReviews);
      setShowFeaturedProducts(settingsRes.data.showFeaturedProducts);
      setShowAddressDetail(settingsRes.data.showAddressDetails);
      setDescriptionSite(settingsRes.data.descriptionSite);
      setKeywordsSite(settingsRes.data.keywordsSite);
      setBlockSite(settingsRes.data.blockSite);
      setBlockSiteInBodyEnd(settingsRes.data.blockSiteInBodyEnd);
      setColors(settingsRes.data.primaryColor);
      setSeccolors(settingsRes.data.secondaryColor);
      setImg(settingsRes.data.logo);
      setShowNavbarCategory(settingsRes.data.showNavHeader);
      setGoogleMapLink(settingsRes.data.googleMapLink);
      setHideProductDescriptionTabs(settingsRes.data.showProductDescriptionTabs);

      setGooglePlayLink(settingsRes?.data?.googlePlayLink ===undefined ? '' : settingsRes?.data?.googlePlayLink);
      setAppleStoreLink(settingsRes?.data?.appleStoreLink ==="undefined" ? '' : settingsRes?.data?.appleStoreLink);
      setCashBack(settingsRes?.data?.cashBack);
      setTwokLink(settingsRes?.data?.twokLink);
      setCouponText(settingsRes?.data?.couponText);
      setWhatsApp(settingsRes?.data?.whatsApp);
      
    }
  }, [loading,settingsRes]);

  const res = useSelector((state) => state.settingsReducers.editSettings);

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("facebook", facebook);
    formData.append("instagram", instagram);
    formData.append("twitter", twitter);
    formData.append("phone", phone);
    formData.append("phone2", phone2);
    formData.append("phone3", phone3);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("googleAnalyticsURL", googleAnalyticsURL);
    formData.append("showCategory", showCategory);
    formData.append("showBrand", showBrand);
    formData.append("vatNumber", vatNumber);
    formData.append("vatValue", vatValue);

    formData.append("commercialRegister", commercialRegister);
    formData.append("titleAr", titleAr);
    formData.append("titleEn", titleEn);
    formData.append("showReviews", showReviews);

    formData.append("showFeaturedProducts", showFeaturedProducts);

    formData.append("showAddressDetails", showAddressDetail);
    formData.append("descriptionSite", descriptionSite);
    formData.append("keywordsSite", keywordsSite);
    formData.append("blockSite", blockSite);
    formData.append("logo", selectedFile);
    formData.append("primaryColor", colors);
    formData.append("secondaryColor", seccolors);
    formData.append("showNavHeader", showNavbarCategory);
    formData.append("googleMapLink", googleMapLink);
    formData.append("showProductDescriptionTabs", hideProductDescriptionTabs);
    formData.append("googlePlayLink",googlePlayLink);
    formData.append("appleStoreLink",appleStoreLink);
    formData.append("cashBack",cashBack);
    formData.append("blockSiteInBodyEnd",blockSiteInBodyEnd);
    formData.append("twokLink",twokLink);
    formData.append("couponText",couponText);
    formData.append("whatsApp",whatsApp);

    //twokLink


    setLoadingEdit(true);

    await dispatch(editSettings(formData));
    setLoadingEdit(false);
  };

  useEffect(() => {
    if (loadingEdit === false) {
      // console.log(res.data)
      if (res && res.data.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getShippingMethods());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingEdit , dispatch ,res]);

  const downloadBackups = async () => {
    setLoadingBackup(true);
    setLoadingExport(true)
    // console.log("hello mostafa you Are ready to download a backup ");
    notify(
      t("Backup has been successfully created and will appear within minutes"),
      "success"
    );
    await dispatch(backup());
    console.log('excute')
    setLoadingBackup(false);
  };

  const backupResponse = useSelector(
    (state) => state.backupRestoreResducer.backup
  );

  useEffect(() => {
    if (loadingBackup === false && backupResponse) {
      console.log('loadingBackup')
      console.log(loadingBackup)
      console.log('data')
      console.log(backupResponse)
      dispatch(getAllBackups());
      setLoadingExport(false)
    }
  }, [backupResponse, loadingBackup , dispatch]);

  // get all backups logs
  const backupLogsResponse = useSelector(
    (state) => state.backupRestoreResducer.allBackupLogs
  );

  //********************** */ delete the dackup log  [FROM DATABASE AND SERVER ] ****************************
  const showDeleteModal = (id) => {
    setBackupId(id);
    setDeleteModal(true);
  };

  const backupLogDelete = async () => {
    setLoadingDeleteBackup(true);
    await dispatch(deleteBackupLog(backupId));
    setLoadingDeleteBackup(false);
  };

  const deleteBackupLogResponse = useSelector(
    (state) => state.backupRestoreResducer.deleteBackupLog
  );

  useEffect(() => {
    if (loadingDeleteBackup === false && deleteBackupLogResponse) {
      notify(t("Delete successfully"), "success");
      setDeleteModal(false);
      setTimeout(async () => {
        await dispatch(getAllBackups());
      }, 1000);
    }
  }, [deleteBackupLogResponse, dispatch, loadingDeleteBackup, t]);

  // Download a backup from database
  const downloadBackup = async (row) => {
    // console.log("Download backup");
    setBackupDownloadName(row?.backupFileName);
    setLoadingDownload(true);
    try {
      const response = await baseURL.post(`/api/v1/backup/log/download/${row._id}`, null, {
        headers: {
          Accept: 'application/octet-stream',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: 'blob'
      });
      // Set the responseType to "blob");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", row.backupFileName);
      document.body.appendChild(link);
      link.click();
      setLoadingDownload(false);
    } catch (error) {
      // console.error(error);
      setLoadingDownload(false);
    }
  };


  //****************** */ restore the database [first we want to show model to confimation] *********************

  const showRestoreDataModal = (row) => {
    // console.log("show RestoreDataModel");
    setRestoreBackupLogName(row?.backupFileName)
    setRestoreDataModal(true);
  };

  const restoreDataBase = async () => {
    // console.log("Hello from Restore The collections Model ");
    // console.log(restoreBackupLogName)
    setLoadingRestoreBackupLog(true)
    setLoadingRestoreLog(true)
    notify(t("The data recovery process has started successfully"), "success");
    setRestoreDataModal(false);
    await dispatch(restoreDackupLog({
      backupFileName: restoreBackupLogName
    }))
    setLoadingRestoreBackupLog(false)

  };

  const restoreBackupLogResponse = useSelector(state => state.backupRestoreResducer.restoreBackupLog)

  useEffect(() => {
    if (loadingRestoreBackupLogName === false) {
      if (restoreBackupLogResponse) {
        // console.log('restoreBackupLogResponse')
        // console.log(restoreBackupLogResponse)
        notify(t("The data has been restored successfully, please login again"), "success");

        setTimeout(async () => {
          localStorage.removeItem("user");
          window.location.replace('/login')
        }, 200);

      }
    }

  }, [loadingRestoreBackupLogName, restoreBackupLogResponse,t])


  // restore the import backup file 
  const onSubmitUploadFile = async () => {
    if (archiveFile === null) return notify(t("Please upload the backup"), "error");
    setLoadingRestore(true)
    setLoadingUploadFile(true)
    const formData = new FormData()
    formData.append('file', archiveFile)
    notify(t("The data recovery process has started successfully"), "success");
    await dispatch(restoreImportBackupFile(formData))
    setLoadingUploadFile(false)

  }

  const uploadFileResponse = useSelector(state => state.backupRestoreResducer.restoreImportFile);

  useEffect(() => {
    if (loadingUploadFile === false) {
      if (uploadFileResponse) {
        // console.log('uploadFileResponse')
        // console.log(uploadFileResponse)
        notify(t("The data has been restored successfully, please login again"), "success");
        setTimeout(async () => {
          localStorage.removeItem("user");
          window.location.replace('/login')
        }, 1500);

      }
    }

  }, [loadingUploadFile]);

  const {sendNotification,sendingNotification}= useSelector(state => state.settingsReducers);

  //const sendingNotification= useSelector(state => state.settingsReducers.sendNotification);
  const sendNotificationToAllUsersFn=async()=>{
    await dispatch(sendNotificationToAllUsers({
      title:messageTitle,
      body:messageBody
    }));
  }

  useEffect(()=>{
    // console.log('sendNotification');
    // console.log(sendNotification);

    if(sendingNotification===false)
    {
      if(sendNotification) notify(t("notification sent successfully!"), "success");
    }
    
  },[sendingNotification]);


  return [
    facebook,
    instagram,
    twitter,
    phone,
    onChangeFacebook,
    onChangeInstagram,
    onChangeTwitter,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    phone3,
    onChangePhone3,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    googleAnalyticsURL,
    onChangeGoogleAnalyticsURL,

    showCategory,
    onChangeShowCategory,
    showBrand,
    onChangeShowBrand,
    vatNumber,
    onChangeVatNumber,
    vatValue,
    onChangeVatValue,
    commercialRegister,
    onChangeCommercialRegister,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showReviews,
    onChangeshowReviews,
    showFeaturedProducts,
    onChangeShowFeaturedProducts,

    showAddressDetail,
    onChangeShowAddressDetail,
    descriptionSite,
    keywordsSite,
    onChangeDescriptionSite,
    onChangeKeywordsSite,
    blockSite,
    onChangeBlockSite,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    onImageChange,
    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    downloadBackups,
    backupLogsResponse,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    backupLogDelete,
    downloadBackup,
    showRestoreDataModal,
    restoreDataModal,
    closeRestoreDataModel,
    restoreDataBase,
    archiveFile,
    onChangeArchiveFile,
    onSubmitUploadFile,
    loadingRestore,
    loadingExport,
    loadingRestoreLog,
    showNavbarCategory,
onChangeShowNavCategoryHeader,
googleMapLink,
onChangeGoogleMapLink,
hideProductDescriptionTabs,
onChangeHideProductDescriptionTabs,

googlePlayLink,
appleStoreLink,
onGooglePlayLinkChange,
onAppleStoreLinkChange,
cashBack,
onChangeCashBack,
blockSiteInBodyEnd,
onChangeBlockSiteInBodyEnd,
twokLink,
onChangeTwokLink,
messageTitle,
setMessageTitle,
messageBody,
setMessageBody,
sendNotificationToAllUsersFn,
couponText,setCouponText,
whatsApp,setWhatsApp

  ];
};
export default AdminSettingsHook;
