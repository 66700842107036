import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import ViewProductsByVendorHook from "../../hook/product/ViewProductsByVendorHook";
import NotFoundPage from "../Utilities/NotFoundPage";
import Pagination from "../../Components/Utilities/Pagination";


const ProductByVendorPage = () => {
  const { vendorName } = useParams();
  const [items, vendorByusername, loading,pagination,getPage] =
    ViewProductsByVendorHook(vendorName);

   //var pageCount = 1;

    if (pagination) var pageCount = pagination;
  else pageCount = 0;

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  // console.log(items);
  return (
    <div style={{ minHeight: "670px" }}>
      {vendorByusername._id || loading ? (
        <>
          <CategoryHeader />
          <div
            style={{
              background: settingsRes?.data?.secondaryColor,
              height: "260px",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                height: "260px",
                alignItems: "center",
              }}
            >
              <img
                src={vendorByusername?.image}
                width="200px"
                className="mx-3"
                alt="vendoe_Image"
              />
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {vendorByusername?.username}
              </div>
            </div>
          </div>
          <Container>
            <Row>
              <Col sm="12" >
                <CardProductsContainer products={items} title="" btntitle="" />
              </Col>
            </Row>
            {/* <Pagination pageCount={pageCount} onPress={onPress} /> */}
          </Container>

          <Pagination pageCount={pageCount} onPress={getPage} />
        </>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};

export default ProductByVendorPage;
