import Cookies from "js-cookie";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import ResetPasswordHook from "../../hook/auth/ResetPasswordHook";

const ResetPasswordPage = () => {
  const [
    webSiteData,
    settingsRes,
    newPassword,
    confirmPassword,
    value,
    onChangeNewPassword,
    onChangeConfirmPassword,
    changePassword
  ] = ResetPasswordHook();

  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {`${t("إعادة تعيين كلمة المرور")}- ${
            Cookies.get("i18next") == "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <div className=" my-3 px-2  justify-content-center ">
        <Row
          className="mt-5 user-address-card-3 p-5 w-50 m-auto"
          style={{
            position: "sticky",
          }}
        >
          <Col xs="10" sm="8" md="6" className="w-100 ">
            <div className="admin-content-text mt-2 mb-4 fw-bolder d-flex justify-content-center">
              {t("إعادة تعيين كلمة المرور")}
            </div>
            <label htmlFor="value" className="d-block mt-3  fw-bolder">
              {t("البريد الإلكتروني أو رقم الجوال")}
            </label>
            <input
              id="value"
              value={value}
              disabled={true}
              type="text"
              className="input-form d-block mt-3 px-3 "
            />
            <label htmlFor="newPassword" className="d-block mt-2 fw-bolder ">
              {t("new password")}
            </label>
            <input
              id="newPassword"
              value={newPassword}
              onChange={onChangeNewPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("enter new password")}
            />
            <label htmlFor="verifyPassword" className="d-block mt-3 fw-bolder ">
              {t("confirm password")}
            </label>
            <input
              id="verifyPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("confirm new password")}
            />
            <div className="d-flex justify-content-end">
              <button
                onClick={changePassword}
                className="btn-save d-inline mt-4 mb-2   "
                style={{ backgroundColor: webSiteData.PRIMARY_COLOR }}
              >
                {t("save")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPasswordPage;
