
import React from "react";
import AdminEditTransaction from "../../Components/Admin/AdminEditTransaction";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditTransactionPage = ()=>{
    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
<AdminEditTransaction/>
        </div>
      </div>
    )
}

export default AdminEditTransactionPage