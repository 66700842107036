import {
    GET_ALL_SHOPIFY_PRODUCTS, ADD_PRODUCTS_FROM_SHOPIFY, LOADING_SHOPIFY_PRODUCTS, ADDING_PRODUCTS_FROM_SHOPIFY, UPDATE_PRODUCTS_FROM_SHOPIFY
    , UPDATING_PRODUCTS_FROM_SHOPIFY
} from "../type";


const initial = {
    shopifyProducts: [],
    addProducts: [],
    updateProductsFromShopify:{},
    isLoading: false,
    isAdding: false,
    isUpdating:false
};

const ShopifyReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_SHOPIFY_PRODUCTS:
            return {
                ...state,
                shopifyProducts: action.payload,
                isLoading: false
            };
        case ADD_PRODUCTS_FROM_SHOPIFY:
            return {
                ...state,
                addProducts: action.payload,
                isAdding: false
            };
        case LOADING_SHOPIFY_PRODUCTS:
            return {
                ...state,
                isLoading: true
            };
        case ADDING_PRODUCTS_FROM_SHOPIFY:
            return {
                ...state,
                isAdding: true
            };
        case UPDATING_PRODUCTS_FROM_SHOPIFY:
            return{
                ...state,
                isUpdating:true
            };
        case UPDATE_PRODUCTS_FROM_SHOPIFY:
            return{
                ...state,
                updateProductsFromShopify:action.payload,
                isUpdating:false
            }


        default:
            return state;
    }
};

export default ShopifyReducer;