import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsAdmin,
  getAllProductsWithPage,
  updateProduct,
  updateProductStatus,
  getAllProductsSearch,
  productsForExcel
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../useNotification";
import { useSearchParams } from "react-router-dom";

// import { utils as XLSX   } from 'xlsx';
//import { saveAs } from 'file-saver';
//import { write as writeXlsx } from 'xlsx-style';
// import { write as writeXlsx } from 'xlsx-style';

const ViewProductAdminHook = (type) => {

  const [loading, setLoading] = useState(true);
  const[items,setItems]=useState([]);
  const[pagination,setPagination]=useState([]);
  const[searchTerm,setSearchTerm]=useState('');
  const[isLoading,setIsLoading]=useState(false);
  const[excelProducts,setExcelProducts]=useState([]);
  const dispatch = useDispatch();

  const[searchParams,setSearchParams]=useSearchParams();
  const user=JSON.parse(localStorage.getItem('user'));

  console.log('user');
  console.log(user);

  const myRef = useRef('');


  useEffect(() => {
   
   // let firstPage;
    // if(localStorage.getItem('page'))
    //  firstPage=localStorage.getItem('page')==0? 1:localStorage.getItem('page');
    // else firstPage=1;
    let firstPage=searchParams.get('page')==null? 1: searchParams.get('page');

    console.log("searchParams.get('page')");
    console.log(searchParams.get('page'));

    getPage(firstPage);

    localStorage.setItem('searchTerm','');
  }, []);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const allproduct = useSelector((state) => state.allProduct.allProductsInSearchPage);
  const excelProductsResponse= useSelector((state)=>state.allProduct.excelProduct);

   console.log('allproduct');
   console.log(allproduct);
  

 // let pagination = [];

 const exportToExcel= async()=>{
  //console.log('done');
  setIsLoading(true);
  await dispatch(productsForExcel());
}

useEffect(()=>{
  if(excelProductsResponse.length>0){
    setIsLoading(false);
    setExcelProducts(excelProductsResponse);
   
  }
},[excelProductsResponse]);

  useEffect(()=>{
    setItems(allproduct.data);
    //else setItems([]);
 
  if (allproduct)
    if (allproduct.numberOfPages) setPagination(allproduct.numberOfPages);
    
    if(items==[]) 
    {
      console.log('all products use effect');

      setItems(allproduct.data);
      //else setItems([]);
   
    if (allproduct)
      if (allproduct.numberOfPages) setPagination(allproduct.numberOfPages);
      //else setPagination([]);
    }
  },[allproduct]);

  // let items = [];
  


  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateProductStatus(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const ress = useSelector((state) => state.allProduct.updateProductStatus);

  useEffect(()=>{
  
      if(items?.length==0) return;

      if(searchTerm===''){
        setSearchTerm('');
        localStorage.setItem('searchTerm','');
        getPage(1);
        localStorage.setItem("page",1);
       // dispatch(getAllProductsAdmin(9));
       return;
      } 
      
      const timeOutId=setTimeout(async()=>{
        localStorage.setItem('searchTerm',searchTerm);
      let query=`keyword=${searchTerm}&limit=9&admin=1`;
      if(type=='vendor') query+=`&vendorId=${user._id}`;

      dispatch(getAllProductsSearch(query));
      localStorage.setItem("page",1);

      },1000);
      
      return ()=>clearTimeout(timeOutId);
  },[searchTerm]);

  

  const searchResponse=useSelector((state)=>state.allProduct.allProducts);
  const loadingProducts=useSelector((state)=>state.allProduct.loadingAdmin);

  // console.log('searchResponse');
  // console.log(searchResponse);

  useEffect(()=>{
    if(searchResponse)
    {
      setItems(searchResponse.data);
      if (searchResponse.numberOfPages) setPagination(searchResponse.numberOfPages);
    } 
  },[searchResponse]);

  // console.log('searchTerm');
  // console.log(searchTerm);
  
  const getPage = async (page) => {
    // console.log('searchTerm');
    // console.log(searchTerm);
    //searchParams.set("page", page);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    localStorage.setItem('page',page);

    let searchTerms=localStorage.getItem('searchTerm');
    let query=`keyword=${searchTerms}&limit=9&page=${page}&admin=1`;
  //  console.log('searchTerms');
  //  console.log(searchTerms);
  //if(type=='vendor') query+=`&vendorId=${user._id}`;
   
  await dispatch(getAllProductsSearch(query));
  };
  
  // console.log('items');
  // console.log(items);

  useEffect(() => {

    if (loading === false) {
      if (ress) {
        console.log('ress');
        console.log(ress);

        notify("تم التعديل بنجاح", "success");
        localStorage.setItem('searchTerm','');
        setSearchTerm('');

        // setItems(()=>updatedItems);
        getPage(localStorage.getItem("page"));
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [items, pagination, getPage, handleShowFun, searchTerm ,setSearchTerm ,settingsRes , exportToExcel , isLoading , excelProducts , loadingProducts];
};

export default ViewProductAdminHook;
