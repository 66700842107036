import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategory,
  getAllCategoriesWithoutBlockedCategory,
  getAllCategories,
} from "../../redux/actions/categoryAction";
import { getAllBrand } from "../../redux/actions/brandAction";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { getAllUsers ,getAllVendors } from "../../redux/actions/usersActions";
import { uploadExcelFile , uploadImagesFromExcel } from "../../redux/actions/excelActions";
import notify from "../../hook/useNotification";
import {
  getAllOrderByAdmin,
  getAllOrderByUser,
  getordersByCoupon,
  getordersByDate,
} from "../../redux/actions/orderAction";

import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
//import Base64JSON from "./readme.json";
//import { Buffer } from "buffer";

const AdminExcelHook = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  //console.log(user);
  const ref = useRef(null);

  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [selectedsubId, setSelectedsubId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [vedorId, setvedorId] = useState();
  const [images, setImages] = useState([]);
  const [isPress, setIsPress] = useState(false);
  const [excel, setExcelFile] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingIMG, setLoadingIMG] = useState(true);
  


  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(getAllCategories());
    dispatch(getAllBrand());
    dispatch(getAllVendors());

     if(user.role!=='admin')
   {
    console.log('vendoooor');
       setvedorId(user);
   } 

  }, []);

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


   function handleImageChange(event){
    //console.log('handleImageChange');
   // let target = event.target.files;
    setImages([...images, ...event.target.files]);

   } 

  

  let category = useSelector((state) => state.allCategory.categories);
  //console.log(category)
  let categoriesWithoutBlocked = useSelector(
    (state) => state.allCategory.categoriesWithoutBlocked
  );

  const brand = useSelector((state) => state.allBrand.brand);
  //console.log(brand);
  
  
  //console.log(getAllUser)
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onSelectCategory = async (value) => {
    if (value !== null) {
      await dispatch(subCategoryByCatId(value._id));
    }
    if(value==null) setSelectedsubId(null);
    setCategoryId(value);
  };

  const onSelectSubCategory = (value) => {
    setSelectedsubId(value);
  };

  const onSelectBrand = (value) => {
    setBrandId(value);
  };

  const onChangeVendor = (value) => {
    setvedorId(value);
  };

  const onChangeSetExcelFile = (event) => {
    console.log('exceeeeeeeeel');
    //console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0])
      //setExcelFile(URL.createObjectURL(event.target.files[0]));
      setExcelFile(event.target.files[0]);
  };

  const getAllUser = useSelector((state) => state.usersReducers.allVendors);
    
  // console.log('getAllUser');
  // console.log(getAllUser);

  // console.log('user');
  // console.log(user);

  useEffect(() => {
    if (user && user.role == "vendor") {
      console.log('vendooooor');
      setvedorId(user._id);
    }
  }, [user]);

  const subCategoryByCat = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );

  useEffect(() => {
    if (categoryId != 0) {
      if (subCategoryByCat) {
        // console.log('sub cat by cat');
        // console.log(subCategoryByCat);
        setSubCategory(subCategoryByCat);
      }
    }
  }, [categoryId]);

  const handleUploadPhotos= async (e) =>{
    //uploadImagesFromExcel
    const formData = new FormData();
    images.map((item) => formData.append("images", item));

    setLoadingIMG(false);
      await dispatch(uploadImagesFromExcel(formData));
     // setIsPress(false);
    setLoadingIMG(true);

  }

  const uploadImagesRes=useSelector((state) => state.excelReducer.uploadImagesFromExcel);

  const handleSubmit = async (e) => {

    console.log('submit');
    setIsPress(true);

    console.log('vedorId');
    console.log(vedorId._id);
    e.preventDefault();

  

    if (
      categoryId === 0 ||
      categoryId == "" ||
      subCategory == "" ||
      subCategory == "0" ||
      selectedsubId == "" ||
      selectedsubId == "0" ||
      vedorId == "" ||
      vedorId == "0" ||
      excel==""
    ) {
      notify("من فضلك اكمل البيانات", "warn");
      setIsPress(false);
      return;
    }


    const formData = new FormData();
    formData.append("category", categoryId._id);
    formData.append("subCategory", selectedsubId._id);
    formData.append("brand", brandId._id);
    formData.append("vendor",
    user.role==='admin' ? vedorId._id : user._id);
    formData.append("excelFile", excel);
     
      setLoading(true);
      await dispatch(uploadExcelFile(formData));

      setIsPress(false);
      setLoading(false);
  };

  const uploadExcelRes = useSelector((state) => state.excelReducer.uploadExcelFile);
  //console.log(uploadExcelRes)

  useEffect(() => {

    console.log(uploadExcelRes);

    if (loading === false) {
        setTimeout(() => setLoading(true), 1500);

        console.log('uploadExcelRes');
        console.log(uploadExcelRes.data);
        let notValidExcel=uploadExcelRes.data;
        let nullProduct=notValidExcel.find(el=>el===null);
        console.log(nullProduct);

        if(nullProduct==null) {
          notify("هناك بعض المنتجات لن يتم اضافتها نظرا لمخالفتها الشروط", "error");
          return;
        }

        if (uploadExcelRes) {
            if (uploadExcelRes.status === 201) {
                notify("تم الاضافة بنجاح", "success");
                return;
              }
              else if(uploadExcelRes.response.status === 400){
                notify("من فضلك راجع البيانات داخل الاكسل فايل تأكد من ادخال الحقول المطلوبة", "error");
                return;
              }
               else {
                notify("فشل في عملية الاضافه", "error");
                return;
              }
        }
        else{
          notify("من فضلك راجع البيانات داخل الاكسل فايل تأكد من ادخال الحقول المطلوبة", "error");
                return;
        }
    }
}, [loading]);

useEffect(()=>{

  if (loadingIMG === false) {

    if(uploadImagesRes)
  {
    if(uploadImagesRes)
    {
      notify("تم رفع الصور بنجاح", "success");
      return;
    }
    else {
      notify("فشل في عملية الرفع", "error");
      return;
    }
  }

  }
  

},[loadingIMG]);




  return [
    onSelectCategory,
    category,
    categoriesWithoutBlocked,
    onSelectSubCategory,
    subCategory,
    onSelectBrand,
    brand,
    webSiteData,
    user,
    onChangeVendor,
    vedorId,
    getAllUser,
    images,
    setImages,
    settingsRes,
    isPress,
    onChangeSetExcelFile,
    handleImageChange,
    handleUploadPhotos,
    categoryId,
    selectedsubId,
    brandId,
    handleSubmit,
    loading,
    loadingIMG
   
  ];
};
export default AdminExcelHook;
