import React from "react";
import { Row, Spinner } from "react-bootstrap";
import UserGetOrdersHook from "../../hook/user/UserGetOrdersHook";
import UserAllOrderItem from "./UserAllOrderItem";
import Pagination from "../../Components/Utilities/Pagination";

import { useTranslation } from "react-i18next";

const UserAllOrder = () => {
  const { t } = useTranslation()
  const [ username,
    result,
    orderData,
    noPages,
    onPress,
    clickHandler,
    handlePrintShipments,
    user,
    showModalReturnProduct,
    description,
    show,
    onChangeDescription,
    handleCloseModel,
    saveReturnProduct,
    loading,
    setLoading
   ] = UserGetOrdersHook();

 // if(!loading) return <Spinner />;

 console.log('orderData',orderData);
// let filteredOrderData=orderData.filter(el=>el.refund!='pending');

  return (
    <div>
      <div className="admin-content-text pb-4">{t("order number")}... #{result} </div>
      <Row className="justify-content-between">
        {orderData.length >= 1 ? (
          orderData.map((el) =><UserAllOrderItem key={el._id} orderItem={el} setLoading={setLoading} loading={loading}   /> )
        ) : (
          <h6>{t("no orders yet")}</h6>
        )}
        <Pagination pageCount={noPages || 0} onPress={onPress} />
      </Row>
    </div>
  );
};

export default UserAllOrder;
