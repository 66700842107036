import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTickets,
  updateTicket,
} from "../../redux/actions/contactUsAction";
import notify from "../useNotification";

const AdminTicketsHook = () => {
  const [loading, setLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);
//   const [show, setShow] = useState(false);
//     const handleShow = ()=> setShow(true)
//   const handleClose = () => setShow(false);


  const dispatch = useDispatch();

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await dispatch(getAllTickets());
      setLoading(false);
    };
    get();
  }, []);

  const tickets = useSelector((state) => state.contactUsReducer.tickets);
  useEffect(() => {
    if (loading === false) {
    }
  }, [loading]);

//   const onClickDeleteHandler = async () => {
    
//     setLoadingDelete(true);
//     await dispatch(updateTicket(ticketId,{
        
//     }));
//     setLoadingDelete(false);
//   };

const handleShowFun = async (id, status) => {
    setLoadingStatus(true);
    await dispatch(
        updateTicket(id, {
        status: status,
      })
    );
    setLoadingStatus(false);
  };

  const resDelete = useSelector((state) => state.contactUsReducer.updateTicket);


  useEffect(() => {
    if (loadingStatus === false) {
      if (resDelete) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllTickets());
        }, 1000);
      }
    }
  }, [loadingStatus]);

  return [
    tickets,
    handleShowFun

    
  ];
};

export default AdminTicketsHook;
