import { UPLOADEXCELFILE , UPLOADIMAGEFROMEXCEL } from "../type";
const inital = {
  uploadExcelFile: [],
  uploadImagesFromExcel:[]
};

const excelReducer = (state = inital, action) => {
  switch (action.type) {
    case UPLOADEXCELFILE:
      return {
        ...state,
        uploadExcelFile: action.payload,
      };
      case UPLOADIMAGEFROMEXCEL:
      return {
        ...state,
        uploadImagesFromExcel: action.payload,
      };

    default:
      return state;
  }
};

export default excelReducer;
