import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import ReactInputVerificationCode from "react-input-verification-code";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOneUser,
  getOneUserWithToken,
} from "../../redux/actions/usersActions";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";

const UserVerify = () => {

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const webSiteData = useSelector((state) => state.settingsReducers.webSiteData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const clearValue = () => setValue("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneUser(id));
    };
    get();
  }, []);

  const res = useSelector((state) => state.usersReducers.oneUser);

  // console.log('res');
  // console.log(res);

  // console.log('webSiteData');
  // console.log(webSiteData);



  const handlePressed = async () => {

    let reversedValue=value.toString().split("").reverse().join("");
    //console.log(reversedValue);
    //return;

    if (res.verifiedCode == reversedValue) {
      setLoading(true);
      await dispatch(getOneUserWithToken(id));
      setLoading(false);
    } else {
      notify("الكود الذى ادخلته غير صحيح", "error");
    }
  };
  const resToken = useSelector((state) => state.usersReducers.oneUserToken);

  useEffect(() => {
    if (loading === false) {
      if (resToken.token) {
        localStorage.setItem("token", resToken.token);
        localStorage.setItem("user", JSON.stringify(resToken));
        notify("تم تسجيل الدخول بنجاح", "success");
        setTimeout(() => {
          if (resToken.role === "admin") {
            window.location.href = "/admin/products";
          } else {
            window.location.href = "/";
          }
        }, 1500);
      }
    }
  }, [loading]);

  return (
    <Container style={{ minHeight: "670px" }}>
      <Row className="py-5 d-flex justify-content-center ">
        <label
          className="mx-auto title-login my-4"
          style={{ textAlign: "center" }}
        >
          ادخل كود التحقق
        </label>

        {/* <p style={{ textAlign: "center" }}>{res.verifiedCode}</p> */}
        {
          /*
          {webSiteData.SMS_DEVELOPMENT === 'true' && <p style={{ textAlign: "center" }}>{res.verifiedCode}</p>}
          */  
        }
        
        
        <div style={{width:'auto'}}>
        <ReactInputVerificationCode
          placeholder=""
          onChange={setValue}
          value={value}
        />
        </div>
       
        <div className="py-5 d-flex justify-content-center">
          <button
            onClick={handlePressed}
            className="btn-save d-inline mt-2 mx-2"
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            disabled={value.length === 4 ? false : true}
          >
            تحقق
          </button>
          <button
            onClick={clearValue}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            حذف
          </button>
        </div>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default UserVerify;
