//import SideBar from "../../Components/Admin/SideBar";
import SideBar from "../../Components/Admin/SideBar";
import AddOffer from "../../Components/Offers/AddOffer";
import VendorSideBar from "../../Components/Vendor/VendorSideBar";
const AddOfferPage = () => {
  const user=JSON.parse(localStorage.getItem('user'));

  return (
    <div className="d-flex justify-content-start">
      <div>
       {user?.role=='admin' ? <SideBar /> : <VendorSideBar /> } 
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AddOffer />
      </div>
    </div>
  );
};

export default AddOfferPage;
