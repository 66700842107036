import React from 'react'
import { useSelector } from 'react-redux';

function CustomButton({children , onClick}) {
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <button
    className="btn-save"
    style={{ backgroundColor: settingsRes?.data?.primaryColor }}
    onClick={onClick}
    >{children}</button>
  )
}

export default CustomButton;