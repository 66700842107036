import React from "react"
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EditCategoryHook from "../../hook/category/EditCategoryHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const AdminEditCategory = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [
    image,
    nameAr,
    nameEn,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    handleSubmit,
    categoryStatus,
    onChangeCategoryStatus
  ] = EditCategoryHook(id)
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (

    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"> {t("category modification")}  </div>
        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={image}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="categoryAr" className="d-block mt-2">  {t("category name in arabic")}</label>
          <input
            id="categoryAr"
            onChange={onChangeNameAr}
            value={nameAr || ''}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("category name in arabic")}
          />
          <label htmlFor="categoryEn" className="d-block mt-2">{t("category name in english")}  </label>
          <input
            id="categoryEn"
            onChange={onChangeNameEn}
            value={nameEn || ''}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("category name in english")}
          />
          <label htmlFor="status" className="d-block mt-2">{t("category state")}</label>
          <select
            value={categoryStatus}
            onChange={onChangeCategoryStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("the status")} </option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={handleSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );

}

export default AdminEditCategory
