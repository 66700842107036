import {
  ADD_OFFER,
  ADD_PRODUCT_TO_OFFER,
  ALL_OFFERS,
  DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID,
  GET_OFFER_DETAILES_BY_OFFER_ID,
  GET_ONE_OFFER,
  UPDATE_OFFER,
} from "../type";

const initalState = {
  allOffers: [],
  addOffer: [],
  oneOffer: [],
  updateOffer: [],
  offerDetailsByOfferId: [],
  deleteProductOfferByOfferDetailId:[],
  addProductOfferDetailToOffer:[]
};

const offersReducers = (state = initalState, action) => {
  switch (action.type) {
    case ALL_OFFERS:
      return {
        ...state,
        allOffers: action.payload,
      };
    case GET_ONE_OFFER:
      return {
        ...state,
        oneOffer: action.payload,
      };
    case ADD_OFFER:
      return {
        ...state,
        addOffer: action.payload,
      };
    case UPDATE_OFFER:
      return {
        ...state,
        updateOffer: action.payload,
      };
    case GET_OFFER_DETAILES_BY_OFFER_ID:
      return {
        ...state,
        offerDetailsByOfferId: action.payload,
      };
    case DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID:
      return {
        ...state,
        deleteProductOfferByOfferDetailId:action.payload
      }
    case ADD_PRODUCT_TO_OFFER:
      return {
        ...state,
        addProductOfferDetailToOffer:action.payload
      }

    default:
      return state;
  }
};

export default offersReducers;
