import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData"
import { ADD_ORDER_HISTORY, GET_ALL_ORDER_HISTORY } from "../type";

export const addOrderHistory = (body) => async(dispatch) => {

try {
    const response = await useInsertData(`/api/v1/orderHistory`,body);
    dispatch({
        type:ADD_ORDER_HISTORY,
        payload:response
    })

} catch (error) {
    dispatch({
        type:ADD_ORDER_HISTORY,
        payload:error.response
    })
}

}

export const getAllOrderHistory = (id) => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/orderHistory/${id}`)
        dispatch({
            type:GET_ALL_ORDER_HISTORY,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:GET_ALL_ORDER_HISTORY,
            payload:error.response
        })
    }
}