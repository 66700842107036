import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import VendorSideBar from "../../Components/Vendor/VendorSideBar";
import VendorAllProducts from "../../Components/Vendor/VendorAllProducts";
import ViewProductAdminHook from "../../hook/admin/ViewProductAdminHook";
import AdminProducts from "../../Components/Admin/AdminProducts";
import ViewProductVendorHook from "../../hook/vendor/ViewProductVendorHook";
import Pagination from "../../Components/Utilities/Pagination";


const VendorAllProductsPage = () => {
  // const [items, pagination, getPage] = ViewProductAdminHook('vendor');
  const [productsByVendorId,searchTerm,setSearchTerm,pagination,getPage] = ViewProductVendorHook();
  
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  // console.log('pagination');
  // console.log(pagination);

  return (
    <Container>
      <Row className="py-3">
        <Col sm="4" xs="4" md="2">
          <VendorSideBar />
        </Col>
        <Col sm="8" xs="8" md="10">
           <VendorAllProducts /> 
          <Pagination pageCount={pageCount} onPress={getPage} />
        </Col>
      </Row>
    </Container>
  );
};

export default VendorAllProductsPage;
