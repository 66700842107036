import {
  CREATE_NEW_USER,
  LOGIN_USER,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  FORGET_PASSWORD_EMAIL_CODE,
  RESET_PASSWORD,
} from "../type";
const inital = {
  createUser: [],
  loginUser: [],
  userProfile: [],
  userPassword: [],
  resetPasswordCode:[],
  resetPassword:[],
  loading: true,
};

const authReducers = (state = inital, action) => {
  switch (action.type) {
    case CREATE_NEW_USER:
      return {
        ...state,
        createUser: action.payload,
        loading: false,
      };
    case LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload,
        loading: false,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        userPassword: action.payload,
        loading: false,
      };
      case FORGET_PASSWORD_EMAIL_CODE:
        return {
          ...state,
          resetPasswordCode: action.payload,
          loading: false,
        };
        case RESET_PASSWORD:
          return {
            ...state,
            resetPassword: action.payload,
            loading: false,
          };
    default:
      return state;
  }
};

export default authReducers;
