import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import add from "../../Images/add.png";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import AdminUserTransactionHook from "../../hook/admin/AdminUserTransactionHook";

const AdminUserTransaction = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [userTransactions] = AdminUserTransactionHook(id);

  const formatDate = (dateString) => {
     const options = { year: "numeric", month: "numeric", day: "numeric" };
     return new Date(dateString).toLocaleDateString(undefined, options);
   };



  const navigate = useNavigate();

  let columns = [
    {
      name: t("type"),
      selector: (row) => (row.type == 1 ? t("deposit") : t("withdraw")),
      sortable: true,
    },
    {
      name: t("price"),
      selector: "price",
      sortable: true,
    },

    {
      name: t("Added by"),
      selector: (row) => row.addedBy?.firstname + `  ` + row.addedBy?.lastname,
      sortable: true,
    },
    {
      name: t("date"),
      selector: (row) =>formatDate(row?.createdAt),
      sortable: true,
    },

    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => navigate(`/admin/eeditTransaction/${row._id}/${id}`)}
          id={row._id}
        >
         { t("Modification")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: userTransactions?.walletList,
  };

  return (
    <div>
      <Helmet>
        <title>{`  ${t("Wallet Reports")}`}</title>
      </Helmet>

      <div className="d-flex justify-content-between">
      <div className="admin-content-text">{t("Wallet Reports")}</div>
      <div className="admin-content-text">{t("current balance")} : {userTransactions?.userCredit}</div>

        <AddButton
          title={t("Add new transaction")}
          navigateTo={`/admin/addTransaction/${id}`}
        />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminUserTransaction;
