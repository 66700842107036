import { GET_ERROR, GET_SUB_BY_CAT_ID, CREATE_SUB_CATEGORY } from "../type";
const inital = {
  subCategory: [],
  subCategoryByCat: [],
  loading: true,
};

const subCategoryReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload,
        loading: false,
      };
    case GET_SUB_BY_CAT_ID:
      return {
        subCategoryByCat: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        subCategory: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default subCategoryReducer;
