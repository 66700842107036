import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ExportProductsFromDropShippingHook from "../../hook/product/ExportProductsFromDropShippingHook";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import SearchByTypingSelect from "../Utilities/SearchByTypingSelect";

const AdminProductsFromDropShipping = () => {
  const { t } = useTranslation();

  const [
    products,
    handleRowSelected,
    settingsRes,
    showAddProductModal,
    setShowAddProductModal,
    categoryId,
    onSelectCategory,
    category,
    selectedsubId,
    subCategory,
    onSelectSubCategory,
    brandId,
    onSelectBrand,
    brand,
    vedorId,
    onChangeVendor,
    getAllUser,
    showModal,
    saveProducts,
    isloading,
    isAdding,
    selectedRows,
    showTable,
    setShowTable,
    setIds,
    handleClick
  ] = ExportProductsFromDropShippingHook();


  

  if(showTable==false) return<>
<label htmlFor="dropShippingMethods" className="d-block mt-2">{t('dropShippingIds')}</label>
<input 
onChange={(e)=>setIds(e.target.value)}
className="input-form d-block mt-2 px-3 w-75" 
placeholder={t('dropShippingIds')} />
<button
onClick={handleClick}
style={{ backgroundColor: settingsRes?.data?.primaryColor }} 
 className="btn-save d-inline mt-2"
 >{t("save")}</button>
  </> 

if(isloading) return <LoadingSpinner />;

  let columns = [
    {
      name: t("Title name"),
      selector: "title",
      sortable: true
    },

    {
      name: t("image"),
      cell: (row) => (
        <>
          {row.image &&
            <img
              alt="sliderImage"
              src={row.image.src}
              width="200px"
              height="100px"
              style={{ padding: "3px", objectFit: "contain" }}
            />}
        </>

      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: t("the status"),
      selector: "status",
      sortable: true
    },
  ];


  const tableData = {
    columns,
    data: products,
  };


  return (
    <div>
      <Helmet>
        <title>{`${t("addProductsDropShippings")}  -  ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>

      <Modal show={showAddProductModal} onHide={() => setShowAddProductModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("add product")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="categoriesProduct" className="d-block mt-4">
            {t("main classification")}
          </label>
          <SearchByTypingSelect
            value={categoryId}
            onChange={onSelectCategory}
            getOptionLabel={(el) => Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
            getOptionValue={(e) => e._id}
            options={category}
            placeholder={t("main classification")}
          />

          <label htmlFor="subCategoriesProduct" className="d-block mt-2 ">
            {t("Subcategory")}
          </label>
          <SearchByTypingSelect
            value={selectedsubId}
            onChange={onSelectSubCategory}
            getOptionLabel={(el) => Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
            getOptionValue={(e) => e._id}
            options={subCategory}
            placeholder={t("Subcategory")}
          />

          <label htmlFor="brand" className="d-block mt-2 ">
            {t("Brand")}
          </label>
          <SearchByTypingSelect
            value={brandId}
            onChange={onSelectBrand}
            getOptionLabel={(el) => Cookies.get("i18next") == "ar" ? el.nameAr : el.nameEn}
            getOptionValue={(e) => e._id}
            options={brand.data}
            placeholder={t("Brand")}
          />

          <label htmlFor="vendor" className="d-block mt-2 ">
            {t("select vendor name")}
          </label>
          <SearchByTypingSelect
              value={vedorId}
              onChange={onChangeVendor}
              getOptionLabel={(el)=>`${el?.firstname} ${el?.lastname}`}
              getOptionValue={(e)=>e._id}
              options={getAllUser}
              placeholder={t("vendor name")}
          />
          <Row>
            <Col sm="12" className="d-flex justify-content-start mt-2">
              <button
              disabled={isAdding}
              onClick={saveProducts}
              className="btn-save d-inline mt-3"
              style={{ 
                backgroundColor: settingsRes?.data?.primaryColor,
                opacity:isAdding ? "0.5":"1"
               }}
              >
                {t("save")}
              </button>
            </Col>
          </Row>




        </Modal.Body>

      </Modal>
      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
          selectableRows
          selectedRows={selectedRows}
          onSelectedRowsChange={handleRowSelected}
        />
      </DataTableExtensions>

      <Row>
        <Col className="d-flex justify-content-start" >
          <button
            onClick={showModal}
            className="btn-save  mt-2"
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("import")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminProductsFromDropShipping;
