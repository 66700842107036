export default function calculatePriceForProductItem(item) {
    let{type,value}=item.Offer;
    let result;
    let price=item?.hasVariant ?  item?.defaultVariant?.price: item?.price1;

    if(type==1) result= price-value;
    else result=price-(price*(value/100));

    if(result<0) result=0;
    return result;
}

export function calculatePriceForVariant(item,variant) {
    let{type,value}=item.Offer;
    let result;
    let price=variant.price;
    console.log('price on variant before offer');
    console.log(price);

    if(type=="1") result= price-value;
    else result=price-(price*(value/100));

    console.log('price on variant after offer');
    console.log(result);
    
    if(result<0) result=0;
    return result;
}