import {
    UPLOADEXCELFILE,
    UPLOADIMAGEFROMEXCEL
   } from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";


export const uploadExcelFile = (body) => async (dispatch) => {
    try {
     
      const response = await useInsertDataWithImage(`/api/v1/excel`, body);
      // console.log(response);
  
      dispatch({
        type: UPLOADEXCELFILE,
        payload: response,

      });
    } catch (error) {
      dispatch({
        type: UPLOADEXCELFILE,
        payload: error,
      });
    }
  };

  export const uploadImagesFromExcel = (body) => async (dispatch) => {
    try {
     
      const response = await useUpdateDataWithImage(`/api/v1/excel`, body);
      // console.log(response);
  
      dispatch({
        type: UPLOADEXCELFILE,
        payload: response,

      });
    } catch (error) {
      dispatch({
        type: UPLOADEXCELFILE,
        payload: error,
      });
    }
  };

