import React, { useState, useEffect } from "react";
import ViewSearchProductHooks from "../product/ViewSearchProductHooks";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCategoriesWithoutBlockedCategory } from "../../redux/actions/categoryAction";
import { getAllProductsSearch , getProductsByCategories } from "../../redux/actions/productsAction";


const NavBarSearchHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();
  const [searchWord, setSearchWord] = useState("");
  const[homeCategories,setHomeCategories]=useState([]);
  const[pagination,setPagination]=useState('');
  const[selectCategoryState,setSelectCategoryState]=useState('');
 //const [, , , getProduct] = ViewSearchProductHooks(searchWord);

let limitNumber = 8;

  useEffect(() => {
    dispatch(getAllCategoriesWithoutBlockedCategory());
    //getProduct();
  }, [dispatch]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const activeCategoriesRes=useSelector((state)=>state.allCategory.categoriesWithoutBlocked);

  //console.log('activeCategoriesRes');
  // console.log(activeCategoriesRes);

  useEffect(()=>{
    if(activeCategoriesRes) setHomeCategories(activeCategoriesRes);
  },[activeCategoriesRes]);


  const onChangeSearch = async (e) => {
    //for pagination
    localStorage.setItem("page",1);
    // if(selectCategoryState=='') await dispatch(getAllProductsSearch(`keyword=${searchWord}&limit=2`,searchWord));
    // else await dispatch(getAllProductsSearch(`categories=${selectCategoryState}&limit=12`,selectCategoryState))
   
    if(selectCategoryState =='') await dispatch(getAllProductsSearch(`keyword=${searchWord}&limit=${limitNumber}`,{keyword:searchWord,type:'keyword'}));
    // else if(selectCategoryState !=='') await dispatch(getAllProductsSearch(`categories=${0}&keyword=${searchWord}&limit=${limitNumber}`,searchWord));
    else await dispatch(getAllProductsSearch(`categories=${selectCategoryState}&limit=${limitNumber}`,{keyword:selectCategoryState,type:'cat'}));

    if (location.pathname !== '/products') {
      navigate('/products');
     // return;
    }

   //await getProduct();
  };

  
  return [onChangeSearch, searchWord, setSearchWord, homeCategories ,pagination ,selectCategoryState,setSelectCategoryState ,settingsRes];
};

export default NavBarSearchHook;