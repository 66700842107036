import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAddProduct from "../../Components/Admin/AdminAddProduct";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import VendorSideBar from "../../Components/Vendor/VendorSideBar";

const AdminAddProductPage = ({ type }) => {
  return (
    <div className="d-flex justify-content-start ">
    {type === 'admin' ?   <div><SideBar /></div> :    <Col sm="3" xs="2" md="2"><VendorSideBar /></Col>}
    
      <div className="p-4" style={{ width: '100%' }}>
        <AdminAddProduct />
      </div>
    </div>

  );
};
export default AdminAddProductPage;
