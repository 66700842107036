import React from "react";
import AdminBrands from "../../Components/Admin/AdminBrands";
import AdminSubCategories from "../../Components/Admin/AdminSubCategories";

import SideBar from "../../Components/Admin/SideBar";

const AdminBrandsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminBrands />
      </div>
    </div>
  );
};

export default AdminBrandsPage;
