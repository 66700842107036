import { Button, Modal } from "react-bootstrap";
import AdminTicketsHook from "../../hook/admin/AdminTicketsHook";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { t } from "i18next";
const AdminTickets = () => {
  const [tickets ,handleShowFun] = AdminTicketsHook();

  const formatDate = (dateString) => {
   // const { t } = useTranslation() ;
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name:t("user name"),
      selector: "name",
      sortable: true,
    },

    {
      name: t("user phone number"),
      selector: "mobile",
      sortable: true,
    },
    {
      name: t("massage title"),
      selector: "msgtitle",
      sortable: true,
    },
    {
      name:t("massage details"),
      selector: "msgtext",
      sortable: true,
    },
    {
      name:t("the status"),
      selector:(row) => row.status == true?t("new"):t("closed"),
      sortable: true,
    },

    {
      name: t("date"),
      selector: (row) => formatDate(row.dateOrdered),
      sortable: true,
    },

    {
        name:t("the status"),
        cell: (row) =>
          row.status === true ? (
            <Button
            variant="success"
              onClick={() => {
                handleShowFun(row._id, false);
              }}
              id={row._id}
            >
              {t("new")}
              <i className="fa fa-unlock mx-1" />
            </Button>
          ) : (
            <Button
             
              variant="danger"
              onClick={() => {
                handleShowFun(row._id, true);
              }}
              id={row._id}
            >
              {t("closed")}
            
              <i className="fa fa-lock mx-1" />
            </Button>
          ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
   
 
  ];

  const tableData = {
    columns,
    data: tickets,
  };

  return (
    <div>


      <div className="admin-content-text">{t("Manage all communication requests")}</div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminTickets;
