import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getSalesReport,
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useParams, useSearchParams } from "react-router-dom";



const ViewSalesProductAdminHook = ({ from, to , loading, setLoading }) => {
    const dispatch = useDispatch();

    const[searchParams,setSearchParams]=useSearchParams();

    const [zeroFlag, setZeroFlag] = useState(true);
    const [pagination, setPagination] = useState(1);
    const [items, setItems] = useState([]);

    const { id } = useParams();

    console.log('id',id);

    const totalPrice1 = () => {
        const totalPrice = items?.reduce((a, b) => a + b?.totalPrice2, 0)?.toFixed(2);
        console.log('totalPrice',totalPrice);
        return totalPrice;
    }

    // const [loading, setLoading] = useState(false);

    const getPage = async (page) => {
        // console.log('from',from);
        // console.log('to',to);
        // searchParams.set("page", page);
        // setSearchParams(searchParams);
        localStorage.setItem('page', page);
        console.log('jjjjjjjjjjjjjjjjjjjj');
         setLoading(true);

        let type= searchParams.get('type');

          // تاجر
        if(id) await dispatch(getSalesReport({from, to, zeroFlag, page,vendorID:id,type}));
        else await dispatch(getSalesReport({from, to, zeroFlag, page,type}));

        setLoading(false);
    }
    
    // useEffect(() => {
    //     // let page=searchParams.get('page') ? searchParams.get('page') : 1;
    //     let page = 1;
    //     dispatch(getSalesReport(from, to, zeroFlag, page));
    // }, []);

    const handleSearchDate = async (from, to) => {
        let page = 1;
        localStorage.setItem('page', page);
        setLoading(true);
        // 'accepted'

        let type= searchParams.get('type');

        if(type==undefined) type="";

        // searchParams.set('type',type);
        // setSearchParams(searchParams);
        // تاجر
        if(id) await dispatch(getSalesReport({from, to, zeroFlag, page,vendorID:id,type}));
        else await dispatch(getSalesReport({from, to, zeroFlag, page,type}));
        
        setLoading(false);
    }

    useEffect(()=>{
        let type= searchParams.get('type');

        if(type==undefined) return;
        handleSearchDate(from,to);
    },[searchParams]);

    //console.log("loading",loading);

    const allproduct = useSelector((state) => state.allProduct.salesReport);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    useEffect(() => {
        // let type= searchParams.get('type');

        // if(type==undefined) return;
        if (allproduct)
            if (allproduct.data) {
                console.log('uuuuuuuuuuuuuuuuuuuuu');
                setItems(allproduct?.data);
                setPagination(allproduct?.numberOfPages);
            }
     
    }, [allproduct]);

  
    

    const totalPrice2 = () => {
        const totalPrice = items?.reduce((a, b) => a + b?.totalPrice2, 0)?.toFixed(2);
        return totalPrice
    }

    return [
        items,
        handleSearchDate,
        totalPrice1,
        totalPrice2,
        zeroFlag,
        setZeroFlag,
        settingsRes,
        pagination,
        getPage,
        loading,
        allproduct
    ];
};

export default ViewSalesProductAdminHook;
