import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands, getAllBrandPage, updateBrands } from "../../redux/actions/brandAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";

const BrandPageHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllBrands());
    window.scrollTo(0,0)
  }, []);
  const brand = useSelector((state) => state.allBrand.allBrands);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateBrands(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.allBrand.updateBrand);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllBrands());
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const clickHandler = (id) => {
    navigate(`/admin/editBrand/${id}`);
  };

  return [brand, clickHandler, handleShowFun , settingsRes]
};

export default BrandPageHook;
