import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import mobile from "../../Images/mobile.png";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const AdminOrderItem = ({ orderItem }) => {
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const currencytype = Cookies.get("i18next") === "ar" ? settingsRes.data.defaultCurrancy.ar : settingsRes.data.defaultCurrancy.en
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <Col sm="12">
      <Link
        to={`/admin/orders/${orderItem._id}`}
        className="cart-item-body-admin my-2 px-1 d-flex px-3"
        style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
      >
        <div className="w-100">
          <Row className="justify-content-between">
            <Col sm="12" className=" d-flex flex-row justify-content-between">
              <div className="d-inline pt-2 cat-text">
                {t("order number")}#{orderItem.orderNumber}
              </div>
              <div className="d-inline pt-2 cat-text">
                {t("date")}#{formatDate(orderItem.createdAt)}
              </div>

              {/* <div className="d-inline pt-2 cat-text">ازاله</div> */}
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col sm="12" className=" d-flex flex-row justify-content-start">
              <div className="d-inline pt-2 cat-title">
                {t("order from")} {" "}
                {orderItem.user.firstname + " " + orderItem.user.lastname}
              </div>
              <div className="d-inline pt-2 cat-rate me-2">
                {orderItem.user.mobile}
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-between">
            <Col sm="12" className=" d-flex">
              <div>
                <div className="d-inline">{t("order state")} </div>
                <div className="d-inline mx-2 stat">
                  {orderItem.orderStatusId && orderItem.orderStatusId.nameAr}
                </div>
              </div>
              <div>
                <div className="d-inline">{t("payment")}</div>
                <div className="d-inline mx-2 stat">
                  {orderItem.isPaid === true ? t("Paid") : t("not done")}
                </div>
              </div>
              <div>
                <div className="d-inline">{t("Payment Method")}</div>
                <div className="d-inline mx-2 stat">
                  {orderItem.paymentMethodId.nameEn === "Cash" ? "كاش" : ""}
                </div>
              </div>
            </Col>
            <Col xs="12" className="d-flex justify-content-end">
              <div>
                <div className="barnd-text">
                  {orderItem.totalPrice || 0} currencytype
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Link>
    </Col>
  );
};

export default AdminOrderItem;
