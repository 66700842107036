
import { useState } from "react";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOneCategory , updateCategory } from "../../redux/actions/categoryAction"
import notify from "../useNotification";

const EditCategoryHook = (id) => {
    const [image, setimage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [nameAr, setNameAr] = useState("")
    const [nameEn, setNameEn] = useState("")
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(true);
    const [categoryStatus,setCategoryStatus] = useState();


    const dispatch = useDispatch()

    useEffect(() => {
        const run = async () => {
            setLoadingData(true);
            await dispatch(getOneCategory(id))
            setLoadingData(false);
        }
        run()
    }, [])

    const category = useSelector((state) => state.allCategory.oneCategory)

    useEffect(() => {
        if (category) {
            setimage(category.image)
            setNameAr(category.nameAr)
            setNameEn(category.nameEn)
            setCategoryStatus(category.status)
        }
    }, [category])

    const onChangeNameAr = (e) => {
        e.persist();
        setNameAr(e.target.value);
    };
    const onChangeNameEn = (e) => {
        e.persist();
        setNameEn(e.target.value);
    };
    const onChangeCategoryStatus = (e) => {
        e.persist();
        setCategoryStatus(e.target.value);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0])
            setimage(URL.createObjectURL(event.target.files[0]));
        setSelectedFile(event.target.files[0]);
    };

  

    // console.log('-------------------------');
    // console.log(category)
    // console.log('-------------------------');

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        if (nameAr === "" ||
            nameEn=== "" 
          
       
        ) {
            notify("من فضلك اكمل البيانات", "warn");
            return;
        }
       
        const formData = new FormData();
        formData.append("nameAr", nameAr);
        formData.append("nameEn", nameEn);
        formData.append("image", selectedFile);
        formData.append("status",categoryStatus)
        

        setTimeout(async () => {
            setLoading(true);
            // console.log('-----------------')
            // console.log(formData)
            // console.log('-----------------')
            await dispatch(updateCategory(id, formData));
            setLoading(false);
        }, 1000);
    };

    const categoryRes = useSelector((state)=>state.allCategory.updateCategory)
    
    useEffect(() => {
        if (loading === false) {
            setTimeout(() => setLoading(true), 1500);
            if (categoryRes) {
                if (categoryRes.status === 200) {
                    notify("تم التعديل بنجاح", "success");
                    
                } else {
                    notify("فشل في عملية الاضافه", "error");
                }
            }
        }
    }, [loading]);

    return [
        image,
        nameAr,
        nameEn,
        onImageChange,
        onChangeNameAr,
        onChangeNameEn,
        handleSubmit,
        categoryStatus,
        onChangeCategoryStatus
    ]

}

export default EditCategoryHook