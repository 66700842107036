import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteUserAddress } from "../../redux/actions/userAddressActions";

const DeleteAddressHook = (id) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    await dispatch(deleteUserAddress(id));
    setShow(false);
    window.location.reload(false);
  };
  return [show, handleClose, handleShow, handleDelete];
};

export default DeleteAddressHook;
