import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editData, getData } from "../../redux/actions/termsPolicyAboutAction";
import notify from "../useNotification";

const TermsPolicyAboutHook = (type) => {
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(true);

  const dispatch = useDispatch();

  const res = useSelector((state) => state.termsPolicyAboutReducer.data);

  useEffect(() => {
    const get = async () => {
      window.scrollTo(0, 0);
      setLoading(true);
      await dispatch(getData(type));
      setLoading(false);

    };
    get();
  }, [dispatch, type]);


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[type])


  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };

  useEffect(() => {
    if (loading === false) {
      if (res && res.data) {
        setNameAr(res.data.nameAr);
        setNameEn(res.data.nameEn);
      }
    }
  }, [loading]);

  const editRes = useSelector(
    (state) => state.termsPolicyAboutReducer.editData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onSubmit = async () => {
    setLoadingEdit(true);

    await dispatch(
      editData(type, {
        nameAr: nameAr,
        nameEn: nameEn,
      })
    );
    setLoadingEdit(false);
  };

  useEffect(() => {
    if (loadingEdit === false) {
      if (editRes && editRes.success === true) {
        notify("تم التعديل بنجاح", "success");
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingEdit]);

  return [nameAr, nameEn, onChangeNameAr, onChangeNameEn, onSubmit, settingsRes];
};

export default TermsPolicyAboutHook;
