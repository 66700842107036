import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { GENERATE_UTTO_LINK } from "../type";

export const generateUttoLink=(body)=>async(dispatch)=>{
    try {
        const response= await useInsertData('/api/v1/ottu',body);
        dispatch({
            type:GENERATE_UTTO_LINK,
            payload:response
        });

    } catch (error) {
        dispatch({
            type:GENERATE_UTTO_LINK,
            payload:error
        });
    }
}

// GENERATE_UTTO_LINK
