import { PAYTAB_START,PAYTAB_RESPONSE } from "../type";
import { useInsertData } from "../../hooks/useInsertData";


export const payTabStart=(body)=>async(dispatch)=>{
    try {
        const response=await useInsertData('/api/v1/paytab/start',body);
        dispatch({
            type:PAYTAB_START,
            payload:response
        });
    } catch (error) {
        dispatch({
            type:PAYTAB_START,
            payload:error
        });
    }
}

export const payTabResponse=(body)=>async(dispatch)=>{
    try {
        const response=await useInsertData('/api/v1/paytab/getResults',body);
        dispatch({
            type:PAYTAB_RESPONSE,
            payload:response
        });
    } catch (error) {
        dispatch({
            type:PAYTAB_RESPONSE,
            payload:error
        });
    }
}