import {
  ADD_VENDOR,
  GET_PRODUCTS_BY_VENDOR_ID,
  GET_ORDERS_BY_VENDOR_ID,
  GET_VENDOR_BY_USERNAME,
  VENDOR_LOADING
} from "../type";
const initial = {
  addVendor: [],
  getProductsByVendorId: [],
  getAllOrdersByVendorId: [],
  vendorByUsername: [],
  vendorLoading:false
};
const vendorReducer = (state = initial, action) => {
  switch (action.type) {
    case ADD_VENDOR:
      return {
        ...state,
        addVendor: action.payload,
      };
    case GET_VENDOR_BY_USERNAME:
      return {
        ...state,
        vendorByUsername: action.payload,
      };
    case GET_PRODUCTS_BY_VENDOR_ID:
      return {
        ...state,
        getProductsByVendorId: action.payload,
        vendorLoading:false
      };

    case GET_ORDERS_BY_VENDOR_ID:
      return {
        ...state,
        getAllOrdersByVendorId: action.payload,
      };
    case VENDOR_LOADING:
      return{
        ...state,
        vendorLoading:true
      };
    default:
      return state;
  }
};

export default vendorReducer;
