import useDeleteData from "../../hooks/useDeleteData";
import { useGetDataToken } from "../../hooks/useGetData";
import {
  useInsertData,
  useInsertDataWithImage,
} from "../../hooks/useInsertData";
import {
  ALL_BACKUP_LOGS,
  BACKUP,
  DELETE_BACKUP_LOG,
  DOWNLOAD_BACKUP,
  RESTORE_BACKUP_FILE,
  RESTORE_BACKUP_LOG,
} from "../type";

// Download A Backup Data
export const backup = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/backup/export`);

    dispatch({
      type: BACKUP,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: BACKUP,
      payload: error.response,
    });
  }
};

// get all Backup Logs to show
export const getAllBackups = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/backup/logs`);
    dispatch({
      type: ALL_BACKUP_LOGS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ALL_BACKUP_LOGS,
      payload: error.response,
    });
  }
};

// delete the backup log from database
export const deleteBackupLog = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/backup/log/${id}`);
    dispatch({
      type: DELETE_BACKUP_LOG,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BACKUP_LOG,
      payload: error.response,
    });
  }
};

 //download the backup from database
// export const downloadBackupLog = (id) => async (dispatch) => {
//   try {
//     const response = await useGetDataToken(`/api/v1/backup/log/download/${id}`);
//     dispatch({
//       type: DOWNLOAD_BACKUP,
//       payload: response,
//     });
//   } catch (error) {
//     dispatch({
//       type: DOWNLOAD_BACKUP,
//       payload: error.response,
//     });
//   }
// };

// restore the database by send the bacup file name in body [from the backup logs table ]
export const restoreDackupLog = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/backup/log/import`, body);
    dispatch({
      type: RESTORE_BACKUP_LOG,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: RESTORE_BACKUP_LOG,
      payload: error.response,
    });
  }
};

// restore the database by import the backup file
export const restoreImportBackupFile = (formData) => async (dispatch) => {
  try {
    const response =await useInsertDataWithImage(`/api/v1/backup/import`, formData);
    dispatch({
      type: RESTORE_BACKUP_FILE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: RESTORE_BACKUP_FILE,
      payload: error.response,
    });
  }
};
