import React from "react";
import { Row, Col, Spinner, Table } from "react-bootstrap";
import add from "../../Images/add.png";
import MultiImageInput from "react-multiple-image-input";
import { CompactPicker } from "react-color";
import { ToastContainer } from "react-toastify";
import AddProductHook from "../../hook/product/AddProductHook";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LoadingBar from "react-top-loading-bar";
import Select from 'react-select';

import Cookies from "js-cookie";
import AddVariantsAndSkus from "../Utilities/AddVariantsAndSkus";
import SkuTable from "../Utilities/SkuTable";

const AdminAddProduct = () => {
  const { t } = useTranslation();
  const [
    subCategory,
    images,
    nameAr,
    nameEn,
    descriptionAr,
    descriptionEn,
    richDescriptionAr,
    richDescriptionEn,
    price1,
    price2,
    price3,
    price4,
    countInStock,
    showPicker,
    colors,
    category,
    brand,
    onSelectSubCategory,
    selectedFeatured,
    onSelectBrand,
    handleChaneComplete,
    onSelectCategory,
    removeColor,
    handleSubmit,
    setImages,
    onChangeNameAr,
    onChangeNameEn,
    onChangeDescriptionAr,
    onChangeDescriptionEn,
    onChangeRichDescriptionAr,
    onChangeRichDescriptionEn,
    onChangePrice1,
    onChangePrice2,
    onChangePrice3,
    onChangePrice4,
    onChangeCountInStock,
    onChangePicker,
    loading,
    allVendorsResponse,
    onChangeVendor,
    vedorId,
    webSiteData,
    ProductStatus,
    onChangeProductStatus,
    descriptionProduct,
    keywordsProducts,
    onChangeDescriptionProduct,
    onChangeKeywordsProducts,
    settingsRes,
    categoryWithout,
    weight,
    onChangeWeight,
    user,
    isPress,
    ref,
    onChangeVideo,
    video,
    value,
    tableSizes,
    onChangeValue,
    clickHandler,
    deleteElement,

    tableSkuSizes,
    settableSkuSizes,
    deleteSkuElement,
    hasChildClassifications,
    onChangeHasChildClassifications,
    DropShippingCode,
    onChangeDropShippingCode

  ] = AddProductHook();


  // console.log('category');
  // console.log(category);


  return (
    <div className="w-100">
      <LoadingBar
        color={webSiteData.PRIMARY_COLOR}
        ref={ref}
        shadow={true}
        height={4}
      />
      <Helmet>
        <title>
          {` ${t("add productt")}- ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("add product")}</div>

        <Tabs
          defaultActiveKey="name"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="name"
            title={t("product")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="productAr" className="d-block mt-2 ">
              {t("product name in arabic")}
            </label>
            <input
              id="productAr"
              onChange={onChangeNameAr}
              value={nameAr}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("product name in arabic")}
            />

            <label htmlFor="productEn" className="d-block mt-2 ">
              {t("product name in english")}
            </label>

            <input
              id="productEn"
              onChange={onChangeNameEn}
              value={nameEn}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("product name in english")}
            />
            <label htmlFor="categoriesProduct" className="d-block mt-2 ">
              {t("main classification")}
            </label>

               <Select
               defaultOptions
               onChange={onSelectCategory}
               getOptionLabel={(el) => Cookies.get("i18next") === "ar"? el.nameAr: el.nameEn}
              getOptionValue={(e) => e._id}
               placeholder= {t("main classification")}
               options={category}
               isClearable
             />
         

            <label htmlFor="subCategoriesProduct" className="d-block mt-2 ">
              {t("Subcategory")}
            </label>

          <Select
          onChange={onSelectSubCategory}
          getOptionLabel={(el) => Cookies.get("i18next") === "ar"? el.nameAr: el.nameEn}
         getOptionValue={(e) => e._id}
          placeholder= {t("Subcategory")}
          options={subCategory}
          isClearable={true}
        />


            <label htmlFor="brand" className="d-block mt-2 ">
              {t("Brand")}
            </label>

            <select
              name="brand"
              onChange={onSelectBrand}
              id="brand"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="">{t("select category")}</option>
              {brand.data
                ? brand.data.map((el) => {
                    return (
                      <option key={el._id} value={el._id}>
                        {Cookies.get("i18next") == "ar" ? el.nameAr : el.nameEn}
                      </option>
                    );
                  })
                : null}
            </select>

            <label htmlFor="amount" className="d-block mt-2 ">
              {t("Quantity available in stock")}
            </label>
            <input
              id="amount"
              onChange={onChangeCountInStock}
              value={countInStock}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Quantity available in stock")}
            />

            <label htmlFor="DropShippingCode" className="d-block mt-2" >
              {t("DropShippingCode")}
            </label>

            <input
            id="DropShippingCode"
            value={DropShippingCode}
            onChange={onChangeDropShippingCode}
            className="input-form d-block mt-2 px-3"
            placeholder={t("DropShippingCode")}

             />

            <label htmlFor="statusProduct" className="d-block mt-2 ">
              {t("product state")}
            </label>
            <select
              id="statusProduct"
              name="status"
              value={ProductStatus}
              onChange={onChangeProductStatus}
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0"> {t("product state")}</option>
              <option value="true">{t("show")}</option>
              <option value="false">{t("hiden")}</option>
            </select>

            
          </Tab>

          <Tab
            eventKey="seo"
            title="  SEO"
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="descriptionProduct" className="d-block mt-2 ">
              {t("Product Description")}
            </label>

            <textarea
              onChange={onChangeDescriptionProduct}
              value={descriptionProduct}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="descriptionProduct"
              placeholder={t("Product Description")}
            />

            <label htmlFor="keywordsProducts" className="d-block mt-2 ">
              {t("Product keywords")}
            </label>

            <textarea
              onChange={onChangeKeywordsProducts}
              value={keywordsProducts}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="keywordsProducts"
              placeholder={t("Product keywords")}
            />
          </Tab>

          <Tab
            eventKey="desc"
            title={t("price")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="price1" className="d-block mt-2 ">
              {t("first price")}
            </label>
            <input
              id="price1"
              onChange={onChangePrice1}
              value={price1}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("first price")}
            />
            <label htmlFor="price2" className="d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price2"
              onChange={onChangePrice2}
              value={price2}
              type="number"
              className="input-form d-block mt-3 px-3"
              placeholder={t("extra price")}
            />
            <label htmlFor="price3" className="d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price3"
              onChange={onChangePrice3}
              value={price3}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("extra price")}
            />
            <label htmlFor="price4" className="d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price4"
              onChange={onChangePrice4}
              value={price4}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("extra price")}
            />

            <label htmlFor="weight" className="d-block mt-2 ">
              {t("weight")}
            </label>
            <input
              id="weight"
              onChange={onChangeWeight}
              value={weight}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("weight")}
            />
          </Tab>

          <Tab
            eventKey="productDescription"
            title={t("Product Description")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="descriptionAr" className="d-block mt-2 ">
              {t("Product Description in arabic")}
            </label>
            <textarea
              id="descriptionAr"
              onChange={onChangeDescriptionAr}
              value={descriptionAr}
              className="input-form-area p-2 mt-2"
              rows="3"
              cols="50"
              placeholder={t("Product Description in arabic")}
            />
            <label htmlFor="descriptionEn" className="d-block mt-2 ">
              {t("Product Description in english")}
            </label>

            <textarea
              id="descriptionEn"
              onChange={onChangeDescriptionEn}
              value={descriptionEn}
              className="input-form-area p-2 mt-2"
              rows="3"
              cols="50"
              placeholder={t("Product Description in english")}
            />
            <label htmlFor="richDescriptionAr" className="d-block mt-2 ">
              {t("Product rish Description in arabic")}
            </label>

            <textarea
              id="richDescriptionAr"
              onChange={onChangeRichDescriptionAr}
              value={richDescriptionAr}
              className="input-form-area p-2 mt-2"
              rows="3"
              cols="50"
              placeholder={t("Product rish Description in arabic")}
            />
            <label htmlFor="richDescriptionEn" className="d-block mt-2 ">
              {t("Product rish Description in english")}
            </label>

            <textarea
              id="richDescriptionEn"
              onChange={onChangeRichDescriptionEn}
              value={richDescriptionEn}
              className="input-form-area p-2 mt-2"
              rows="3"
              cols="50"
              placeholder={t("Product rish Description in english")}
            />
            <label htmlFor="featured" className="d-block mt-2 ">
              {t("Featured Product")}
            </label>
            <select
              name="featured"
              onChange={selectedFeatured}
              id="featured"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="false"> {t("no")}</option>
              <option value="true"> {t("yes")}</option>
            </select>
          </Tab>

          <Tab
            eventKey="colors"
            title={t("Colors and sizes")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="colors" className="d-block mt-3 ">
              {t("Available colors for the product")}
            </label>
            <div className="mt-1 d-flex" id="colors">
              {colors.length >= 1
                ? colors.map((c, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => removeColor(c)}
                        className="color ms-2 border  mt-1"
                        style={{ backgroundColor: c }}
                      ></div>
                    );
                  })
                : null}

              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePicker}
              />

              {showPicker === true ? (
                <CompactPicker onChangeComplete={handleChaneComplete} />
              ) : null}
            </div>

            <label htmlFor="sizes" className="d-block mt-3 ">
              {t("Available sizes")}
            </label>

            <div>
              <input
                style={{
                  borderColor: "gray",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderRadius: "6px",
                }}
                value={value}
                onChange={onChangeValue}
                className="d-inline-block mx-3 mt-2 p-1 "
                placeholder="value"
              />
              <button
                onClick={() => clickHandler()}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
              >
                {t("add")}
              </button>
            </div>
            <Col sm-8>
              <Table
                striped
                bordered
                hover
                className="mt-3 mx-5 w-50"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("size")} </th>
                    <th>{t("delete")} </th>
                  </tr>
                </thead>
                <tbody>
                  {tableSizes
                    ? tableSizes.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{el}</td>
                          <td>
                            <i
                              onClick={() => deleteElement(index)}
                              className="fa-regular fa-trash-can"
                            ></i>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </Col>
          </Tab>

          <Tab
           eventKey="classification"
           title={t("classification")}
           style={{ backgroundColor: "white" }}
          >

            <label htmlFor="have child classifications" className="d-block mt-2 ">
              {t("have child classifications")}
            </label>

            <select
            id="have child classifications"
            className="select input-form-area mt-2 px-2"
            value={hasChildClassifications}
            onChange={onChangeHasChildClassifications}
            >
              <option value={false}>{t("no")}</option>
              <option value={true}>{t("yes")}</option>
            </select>

            {
              hasChildClassifications=="true"&&(
                <>
                <label htmlFor="sizes" className="d-block mt-3 ">
                {t("classification")}
              </label>
              <div>
                <AddVariantsAndSkus
                tableSkuSizes={tableSkuSizes}
                settableSkuSizes={settableSkuSizes}
                settingsRes={settingsRes}
                 />
  
               <Col sm-8>
               <SkuTable
               tableSizes={tableSkuSizes}
               deleteElement={deleteSkuElement}
               keys={['title','price','weigth','quantity','image']}
               headers={['#',t("Title name"),t("price"),t("weight"),t("Quantity"),t("pictureLink"),t("delete")]}
                />
              </Col>
  
              </div>
              </>
              )
            }
          


          </Tab>
          {webSiteData.MULTI_VENDOR == "true" && user?.role === "admin" && (
            <Tab
              eventKey="vendor"
              title={t("select vendor")}
              style={{ backgroundColor: "white" }}
            >
              <label htmlFor="vendor" className="d-block mt-2 ">
                {t("select vendor")}
              </label>
              <select
                onChange={onChangeVendor}
                value={vedorId}
                id="vendor"
                className="select input-form-area mt-3 px-2 "
                name="vendors"
              >
                <option value="0">{t("select vendor")}</option>
                {allVendorsResponse
                  ? allVendorsResponse.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el?.firstname} {el?.lastname}
                        </option>
                      );
                    })
                  : null}
              </select>
            </Tab>
          )}

          <Tab
            eventKey="home"
            title={t("product image")}
            style={{ backgroundColor: "white" }}
          >
            {/* <div className="text-form pb-2"> {t("product image")}</div> */}
            <div className="mt-3">
              <MultiImageInput
                images={images}
                setImages={setImages}
                theme="light"
                max={5}
                // cropConfig={{ crop, ruleOfThirds: true }}
                allowCrop={false}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="video" className="d-block mt-2 ">
                {t("video")}
              </label>
              <input
                id="video"
                onChange={onChangeVideo}
                value={video}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("video")}
              />
            </div>
          </Tab>
        </Tabs>
      </Row>

      <Row>
        <Col sm="12" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            disabled={isPress === true ? true : false}
          >
            <div className="d-flex justify-content-evenly align-items-center">
              <p
                style={{
                  all: "unset",
                }}
              >
                {t("save")}
              </p>
              {isPress === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : null}
            </div>
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminAddProduct;



// {/**
               
// <select
//           id="categoriesProduct"
//           name="categories"
//           onChange={onSelectCategory}
//           className="select input-form-area mt-2 px-2 "
//         >
//           <option value="0">{t("main classification")}</option>

//           {category.data
//             ? category?.data.map((el) => {
//                 return (
//                   <option key={el._id} value={el._id}>
//                     {Cookies.get("i18next") === "ar"
//                       ? el.nameAr
//                       : el.nameEn}
//                   </option>
//                 );
//               })
//             : null}
//         </select>

       

//            */}