import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../Components/Admin/SideBar"
import VideosLearning from "../../Components/Utilities/VideosLearning"
import VendorSideBar from "../../Components/Vendor/VendorSideBar";

const VideosLearningPage = () => {
    var user = "";
    if (localStorage.getItem("user") != null) {
      user = JSON.parse(localStorage.getItem("user"));
    }

    return(
        
            user?.role === 'admin'?(
                <div className="d-flex justify-content-start">
                <div>
                  <SideBar />
                </div>
                <div className="p-4" style={{ width: '100%' }}>
                <VideosLearning/>
                </div>
              </div>
            ):(
                <Container >
                <Row className='py-3'>
                    <Col sm="3" xs="2" md="2">
                    <VendorSideBar/>

                    </Col>
                    <Col sm="9" xs="10" md="10">
                    <VideosLearning/>
                    </Col>
                </Row>
            </Container>
            )
        
     
    )

}

export default VideosLearningPage