import {
  CREATE_PRODUCT,
  GET_ERROR,
  GET_ALL_PRODUCT,
  GET_PRODUCT_DETAILS,
  GET_SIMILAR_PRODUCTS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT_CATEGORY,
  GET_ALL_PRODUCT_SUB_CATEGORY,
  GET_ALL_PRODUCT_BRAND,
  GET_SALES_REPORT,
  UPDATE_PRODUCT_STATUS,
  GET_ALL_PRODUCT_ADMIN,
  ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER,
  GET_ALL_OFFERS,
  PRODUCTS_FEATURED_LIMIT,
  ALL_PRODUCTS_FOR_EXCEL,
  LOADING_ADMIN,
  GET_SEARCH_PRODUCTS,
  RESET_STORED_PRODUCTS

} from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData, useUpdateDataWithImage } from "../../hooks/useUpdateData";

export const createProduct = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/products`, formData);

    dispatch({
      type: CREATE_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};
export const getAllProducts = (limit,status) => async (dispatch) => {
  try {

    let url=`/api/v1/products/get/page/website?limit=${limit}`;
    if(status) url+=`&status=true`;

    const response = await useGetData(url);

    dispatch({
      type: GET_ALL_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getAllProductsAdmin = (limit) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?limit=${limit}`
    );

    dispatch({
      type: GET_ALL_PRODUCT_ADMIN,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getSalesReport = ({from, to, zeroFlag,page=1,vendorID,type}) => async (dispatch) => {
  try {
    let api=`/api/v1/orders/get/orderItemsReport?from=${from}&to=${to}&checkZero=${zeroFlag}&page=${page}&type=${type}`;

    if(vendorID) api+=`&vendorID=${vendorID}`;

    const response = await useGetDataToken(
      api
    );

    dispatch({
      type: GET_SALES_REPORT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_SALES_REPORT,
      payload: error,
    });
  }
};


export const getAllProductsSearch = (queryString,keyword) => async (dispatch) => {
  try {
    dispatch({type:LOADING_ADMIN});
    const response = await useGetData(
      `/api/v1/products/get/page/website/search?${queryString}`
    );

    dispatch({
      type: GET_SEARCH_PRODUCTS,
      payload: response,
      keyword:keyword,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getAllProductsWithPage = (page, limit) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?page=${page}&limit=${limit}`
    );

    dispatch({
      type: GET_ALL_PRODUCT_ADMIN,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getOneProduct = (id) => async (dispatch) => {
  try {
   // dispatch({type:LOADING_ADMIN});

    const response = await useGetData(`/api/v1/products/${id}`);

    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getSimilarProducts = (id) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?categories=${id}`
    );

    dispatch({
      type: GET_SIMILAR_PRODUCTS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

// get product by category
export const getProductsByCategories = (categoryId, limit, page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?categories=${categoryId}&limit=${limit}&page=${page}`
    );

    dispatch({
      type: GET_ALL_PRODUCT_CATEGORY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT_CATEGORY,
      payload: error.response,
    });
  }
};
// get product by brand
export const getProductsByBrand = (brandId, limit, page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?brand=${brandId}&limit=${limit}&page=${page}`
    );

    dispatch({
      type: GET_ALL_PRODUCT_BRAND,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT_BRAND,
      payload: error.response,
    });
  }
};

// get product by sub category
export const getProductsBySubCategories = (subCategoryId, limit, page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products/get/page/website?subCategory=${subCategoryId}&limit=${limit}&page=${page}`
    );

    dispatch({
      type: GET_ALL_PRODUCT_SUB_CATEGORY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT_SUB_CATEGORY,
      payload: error.response,
    });
  }
};
export const deleteProduct = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/products/${id}`);

    dispatch({
      type: DELETE_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};
export const updateProduct = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/products/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

// UPDATE PRODUCT STATUS
export const updateProductStatus = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/products/editProductStatus/${id}`,
      formData
    );

    // console.log('updateProductStatus');
    // console.log(response);
    dispatch({
      type: UPDATE_PRODUCT_STATUS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

// get All Product That Have No Offer and Filter It if there query [category , sub Category , brand ]
export const getAllProductsHasNoOfferAndFilter = (categoryId,subCategoryId,brandId , vedorId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products/allProducts/noOffers?subCategory=${subCategoryId}&brand=${brandId}&categories=${categoryId}&vedorId=${vedorId}`)
    dispatch({
      type:ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER,
      payload:error.response
    })
  }
}

//get all offers for home page
export const allOffersInHomePage= (status) => async (dispatch)=>{

  try {
    let url=`/api/v1/offerDetails`;
    if(status) url+=`?status=true&page=1&limit=8`;
    const response = await useGetData(url);

    dispatch({
      type: GET_ALL_OFFERS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_OFFERS,
      payload: error,
    });
  }

}

//get products featured with limit 
export const productsFeaturedWithLimit = (limit,status) => async (dispatch) => {
  try {
    let url=`/api/v1/products/get/featured?limit=${limit}`;
    if(status) url+=`&status=true`;
    const response = await useGetData(url);
    dispatch({
      type:PRODUCTS_FEATURED_LIMIT,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:PRODUCTS_FEATURED_LIMIT,
      payload:error.response
    })
  }
}

//get all products for excel file for admin
export const productsForExcel=()=>async(dispatch)=>{
  try {
    const response=await useGetData("/api/v1/products/admin/excel");
    dispatch({
      type:ALL_PRODUCTS_FOR_EXCEL,
      payload:response
    });
  } catch (error) {
    dispatch({
      type:ALL_PRODUCTS_FOR_EXCEL,
      payload:error.response
    });
  }
};

// reset all products in redux
export const resetStoredProducts=()=>async(dispatch)=>{
  try {
    dispatch({
      type:RESET_STORED_PRODUCTS
    });
  } catch (error) {
    
  }
}


