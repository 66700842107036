import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";

import {
  getAllOrderByAdmin,
  getAllOrderByUser,
  getordersByCoupon,
  getordersByDate,
} from "../../redux/actions/orderAction";
import { useSearchParams } from "react-router-dom";

const AdminAllOrdersHook = ({from,to,loadingSpinner, setLoadingSpinner}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingDate, setLoadingDate] = useState(true);

  const [loadingCoupon, setLoadingCoupon] = useState(true);

  const [result, setResult] = useState(0);
  const [noPages, setNoPages] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [couponSearch, setCouponSearch] = useState('');

  const[searchParams,setSearchParams]=useSearchParams();

  // const [from, setFrom] = useState(new Date().toISOString().split("T")[0]);
  // const [to, setTo] = useState(new Date().toISOString().split("T")[0]);
  const [pagination, setPagination] = useState(1);


  const user = JSON.parse(localStorage.getItem("user"));

  const getPage=async(page)=>{
   // let page=1;
    searchParams.set('from',from);
    searchParams.set('to',to);
    searchParams.set('page',page);
    setSearchParams(searchParams);
    setLoadingSpinner(true);
    //setLoadingDate(true);
    await dispatch(getordersByDate(from, to,page));
    //setLoadingDate(false);
    setLoadingSpinner(false);
  }



  const handleRefresh = async () => {
    setLoading(true);
    await dispatch(getAllOrderByAdmin());
    setLoading(false);

    setCouponSearch('')
  };

  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  let username = "";
  if (user != null) {
    username = user.firstname + " " + user.lastname;
  }

  const orderBydate = useSelector((state) => state.orderReducer.orderBydate);



  const handleSearchCoupon = async () => {
    setLoadingCoupon(true)
    await dispatch(getordersByCoupon(couponSearch));
    setLoadingCoupon(false)
  };

  // useEffect(() => {
  //   if (loadingCoupon === false) {
  //     if (res && res.data) {
  //       setOrderData(res.data);
  //     }
  //   }
  // }, [loadingCoupon]);



  const handleSearchDate = async () => {
    let page=1;
    searchParams.set('from',from);
    searchParams.set('to',to);
    searchParams.set('page',page);
    setSearchParams(searchParams);
    localStorage.setItem('page',1);
    setLoadingSpinner(true);
    //setLoadingDate(true);
    await dispatch(getordersByDate(from, to,page));
    //setLoadingDate(false);
    setLoadingSpinner(false);
  };

  useEffect(() => {
      if (orderBydate && orderBydate?.data) {
        setOrderData(orderBydate.data);
        setPagination(orderBydate?.numberOfPages);
      }
  }, [orderBydate]);


  const get = async () => {
    if(searchParams.get('from')){
      setLoadingSpinner(true);
     await dispatch(getordersByDate(searchParams.get('from'), searchParams.get('to'),searchParams.get('page')));
     setLoadingSpinner(false);

     localStorage.setItem('page',searchParams.get('page'));
    }
    // setLoading(true);
    // await dispatch(getAllOrderByAdmin());
    // setLoading(false);
  };
  const onPress = async (page) => {
    setLoading(true);
    await dispatch(getAllOrderByAdmin(5, page));
    setLoading(false);
  };

  // const onChangeFrom = (e) => {
  //   setFrom(e.target.value);
  // };
  // const onChangeTo = (e) => {
  //   setTo(e.target.value);
  // };
  const onChangeCouponSearch = (e) => {
    setCouponSearch(e.target.value);
  };

  useEffect(() => {
    get();
  }, []);

  const res = useSelector((state) => state.orderReducer.ordersByAdmin);

  // useEffect(() => {
  //   if (loading === false) {
  //     if (res && res.result) {
  //       setResult(res.result);
  //     }
  //     if (res && res.numberOfPages) {
  //       setNoPages(res.numberOfPages);
  //     }
  //     if (res && res.data) {
  //       setOrderData(res.data);
  //     }
  //   }
  // }, [loading]);

  return [
    username,
    result,
    orderData,
    noPages,
    onPress,
    webSiteData,
    settingsRes,
    // from,
    // to,
    // onChangeTo,
    // onChangeFrom,
    handleSearchDate,
    couponSearch,
    onChangeCouponSearch,
    handleSearchCoupon,
    handleRefresh,
    loadingDate,
    pagination,
    getPage
  ];
};

export default AdminAllOrdersHook;
