import { GET_SETTINGS, EDIT_SETTINGS, GET_WEBSITE_DATA , SEND_NOTIFICATION_TO_ALL_USERS , SENDING_NOTIFICATION , LOADING_SETTING } from "../type";
import {
  useInsertDataWithImage,
  useInsertData,
} from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";

export const getSettings = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/settings`);

    await dispatch({type:LOADING_SETTING});

    dispatch({
      type: GET_SETTINGS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_SETTINGS,
      payload: error,
    });
  }
};

export const getWebsiteData = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/settings/getWebsiteData`);

    dispatch({
      type: GET_WEBSITE_DATA,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_WEBSITE_DATA,
      payload: error,
    });
  }
};

export const editSettings = (body) => async (dispatch) => {
  try {
   
    const response = await useInsertDataWithImage(`/api/v1/settings/edit`, body);

    dispatch({
      type: EDIT_SETTINGS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EDIT_SETTINGS,
      payload: error,
    });
  }
};

export const sendNotificationToAllUsers=(body)=>async(dispatch)=>{
  try {

    dispatch({type:SENDING_NOTIFICATION});

    const response= await useInsertData('/api/v1/notification/sendToAllUsers',body);
    dispatch({
      type:SEND_NOTIFICATION_TO_ALL_USERS,
      payload:response
    });
  } catch (error) {
    dispatch({
      type:SEND_NOTIFICATION_TO_ALL_USERS,
      payload:error.message
    });
  }
}
