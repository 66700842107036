import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import AdminAllOrdersHook from "../../hook/admin/AdminAllOrdersHook";
import AdminOrderItem from "./AdminOrderItem";
import Pagination from "../../Components/Utilities/Pagination";
import UserCartHook from "../../hook/cart/UserCartHook";
import CartItem from "../Cart/CartItem";
import AdminCartHook from "../../hook/cart/AdminCartHook";
import { useParams } from "react-router-dom";
import CartAdminItem from "../Cart/CartAdminItem";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminCartReportDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    itemNumber,
    cartItems,
    totalPrice,
    couponNameAfterApplay,
    totalPriceAfterDiscount,
  ] = AdminCartHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <Container style={{ minHeight: "700px" }}>
      <Row>
        <div className="cart-title"></div>
      </Row>
      <div className="d-flex justify-content-between">
        <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }}>{t("cart details")}</div>
        <div style={{ fontWeight: "bold" }}>{t("total")} {totalPrice || 0}</div>
      </div>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="9">
          {cartItems.length >= 1 ? (
            cartItems.map((item) => {
              return <CartAdminItem key={item._id} item={item} />;
            })
          ) : (
            <h6>{t("There are no products in the cart")}</h6>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCartReportDetails;
