import React, { useState } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import UserGetOrdersHook from "../../hook/user/UserGetOrdersHook";
import mobile from "../../Images/mobile.png";
import { useTranslation } from "react-i18next";
import { getAllOrderByUser, getOneOrder, refundOneItem } from "../../redux/actions/orderAction";
import { useDispatch } from "react-redux";
import notify from "../../hook/useNotification";

// refundOneItem
const UserAllOrderCard = ({ item,setLoading,loading }) => {
  const{id}=useParams();

  const[searchParams,setSearchParams]=useSearchParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [users, clickHandler, webSiteData, handleShowFun, showReviews] =
    ViewAllUsersHook();

  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState(item?.refundNotes ? item?.refundNotes : "");

  const user = JSON.parse(localStorage.getItem("user"));

 // console.log('id',id);


  const refundOrderItem = async () => {
    try {

      await dispatch(refundOneItem(
        {
          id: item?._id,
          body: {
            refund: "pending",
            refundNotes: description
          }
        }
      ));

      notify("تم ارسال طلب الاسترجاع بنجاح","success");

      setShowModal(false);
      setLoading(true);
      await dispatch(getAllOrderByUser(user._id, 5, searchParams.get('page') ));
      setLoading(false);


    } catch (error) {
      console.error('message', error);
      notify("حدث خطأ حاول مرة اخري","error");

    }
  }

  const refundOrderItemByAdmin=async()=>{
    try {
      await dispatch(refundOneItem(
        {
          id: item?._id,
          body: {
            refund: "accepted",
            orderID:id,
            admin:true,
            addedBy: user?._id
          }
        }
      ));

      notify("تم ازالة المنتج من الطلب بنجاح","success");


      setShowModal(false);

      setLoading(true);
      await dispatch(getOneOrder(id));
      setLoading(false);

    } catch (error) {
      notify("حدث خطأ حاول مرة اخري","error");

    }
  }


  console.log('loading', loading);

  return (
    <div>
      {
        showModal && <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>
              <div className="font">{t("من فضلك أدخل سبب الإسترجاع ")}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div className="font">
              <div className="font">
                <textarea
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="input-form-area p-2 mt-2"
                  rows="4"
                  cols="50"
                  placeholder={t("سبب الإسترجاع")}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="font"
              onClick={() =>{
                user?.role=='admin' ? refundOrderItemByAdmin() : refundOrderItem()
              } }
            >
              { loading && <Spinner />} {t("Save Changes")}
            </Button>
          </Modal.Footer>
        </Modal>
      }
      <Row className="d-flex mb-2">
        <Col xs="3" md="2" className="d-flex justify-content-start">
          <Link
            to={`/product/${item.product._id}`}
            style={{ textDecoration: "none" }}
          >
            <img
              width="93px"
              height="120px"
              src={(item.product && item.product.image) || mobile}
              alt=""
              style={{ objectFit: "contain" }}
            />
          </Link>
        </Col>
        <Col xs="12" md="6">
          <div className="d-inline pt-2 cat-title">
            {(item.product && item.product.nameAr) || ""}
          </div>
          {showReviews ? (
            <div>
              {" "}
              <div className="d-inline pt-2 cat-rate me-2">
                {(item.product && item.product.ratingsAverage) || 0}
              </div>
              <div className="rate-count d-inline p-1 pt-2">
                ({(item.product && item.product.ratingsQuantity) || 0} {t("rating")})
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="mt-3 d-flex">
            <div className="cat-text mt-1 d-inline">{t("Quantity")}</div>
            <input
              value={item.quantity}
              className="mx-2 "
              type="number"
              style={{ width: "40px", height: "30px" }}
            />
            <div className="cat-text mt-1 d-inline">{t("weight")}</div>
            <div
              className="mx-2 mt-1"
            >
              {item.product.weight / 1000}
            </div>
            <div className="cat-text mt-1 d-inline">{t("Color")}</div>
            <div
              className="color ms-2 mx-2"
              style={{
                backgroundColor: item.color,
              }}
            ></div>
          </div>

          <div className="my-3 cat-text">{t("classification")} :</div>
          <div>{item?.variant?.title}</div>

          <div className="mt-3 d-flex">
            <div className="cat-text mt-1 d-inline">{t("Notes")}: </div>
            <div>  {item.productNotes}  </div>
          </div>

          {  //   user.role == 'user' &&item?.refund == '' &&
            user.role == 'user' &&item?.refund == '' && <div className="my-3 d-flex">
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-warning"
                style={{ color: "white" }}> { loading && <Spinner />} استرجاع </button>
            </div>
          }

          {
           user.role == 'user' && item?.refund == 'pending' && <div className="my-3 d-flex alert alert-success" role="alert">
              تم ارسال طلب استرجاع لهذا المنتج
            </div>
          }

          {
            user.role == 'admin' && item?.refund == 'pending' && <div className="my-3 d-flex">
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-warning"
                style={{ color: "white" }}> استرجاع </button>
            </div>
          }

          {
            item?.refund == 'accepted' && <div className="my-3 d-flex alert alert-primary" role="alert">
            تم قبول طلب الاسترجاع
          </div>
          }

        </Col>

        <hr />
      </Row>
    </div>
  );
};

export default UserAllOrderCard;
