import Select from 'react-select';

export default function SearchByTypingSelect({value,onChange,getOptionLabel,getOptionValue,options,placeholder}) {

    // console.log('select value');
    // console.log(value)
  return (
    <Select
    isClearable={true}
    defaultOptions
    value={value}
    onChange={onChange}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    options={options}
    placeholder={placeholder}
     />
  )
}
