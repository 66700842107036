import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EditPaymentMethodHook from "../../hook/checkout/EditPaymentMethodHook";
import EditShippingHook from "../../hook/admin/EditShippingHook";
import addSetting from "../../Images/add-setting.png"
import { useSelector } from "react-redux";

const AdminEditShippingMethod = () => {

  const { id } = useParams();

  const [
    paymentMethod_Ar,
    paymentMethod_En,
    paymentMethodStatus,
    onchangePaymentMethod_Ar,
    onchangePaymentMethod_En,
    onchangePaymentMethodStatus,
    onSubmit,
    cost,
    onChangeCost,
    tablekeysValues,
    deleteElement,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    show,
    handleShow,
    wieghtNumber,
    onChangeWieghtNumber,
    wieghtCost,
    onChangeWieghtCost,
    wieghtExtraCost,
    onChangeWieghtExtraCost,
  ] = EditShippingHook(id)

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل طريقة الشحن </div>
        <Col sm="8">
          <input
            value={paymentMethod_Ar}
            onChange={onchangePaymentMethod_Ar}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="اسم طريقة الشحن بالغه العربيه "
          />
          <input
            value={paymentMethod_En}
            onChange={onchangePaymentMethod_En}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="اسم طريقة الشحن بالغه الانجليزيه "
          />

          <input
            value={wieghtNumber}
            onChange={onChangeWieghtNumber}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" عدد الوزن  "
          />
          <input
            value={wieghtCost}
            onChange={onChangeWieghtCost}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" تكلفة الوزن  "
          />
          <input
            value={wieghtExtraCost}
            onChange={onChangeWieghtExtraCost}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" تكلفة الوزن الاضافيه  "
          />


          <select
            value={paymentMethodStatus}
            onChange={onchangePaymentMethodStatus}
            name="type"
            id="type"
            className="select input-form-area mt-3 px-3 "
          >
            <option value="0"> الحاله</option>
            <option value="true">نشط</option>
            <option value="false">غير نشط</option>
          </select>

          <label className="mt-2" htmlFor="setting" ><strong>إضافة الاعدادات</strong></label>
          <div>
            <img
              id='setting'

              src={addSetting}
              alt=""
              width="50px"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow()}
            />

          </div>

          {show ? <div>
            <input
              style={{
                borderColor: "gray",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
              }}
              value={key}
              onChange={onChangeKey}
              className="d-inline-block  mt-2 p-1 "
              placeholder="key"
            />
            <input
              style={{
                borderColor: "gray",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
              }}
              value={value}
              onChange={onChangeValue}
              className="d-inline-block mx-3 mt-2 p-1 "
              placeholder="value"
            />
            <button
              onClick={() => clickHandler()}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              إضافه
            </button>
          </div> : ''}

        </Col>
        <Col sm-8>
          <Table striped bordered hover className="mt-3 mx-5 w-50" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>#</th>
                <th> Key</th>
                <th colSpan='2'>value </th>
              </tr>
            </thead>
            <tbody>
              {
                tablekeysValues ? tablekeysValues.map(el => (
                  <tr>
                    <td>{el.count}</td>
                    <td>{el.key}</td>
                    <td >{el.value}</td>
                    <td><i onClick={() => deleteElement(el.count)} className="fa-regular fa-trash-can"></i></td>


                  </tr>
                )) : ''
              }

            </tbody>
          </Table>
        </Col>

      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>حفظ التعديل</button>


        </Col>

      </Row>
      <ToastContainer />
    </div>
  )

}

export default AdminEditShippingMethod