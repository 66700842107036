import SideBar from "../../../Components/Admin/SideBar"
import OfferDetails from "../../../Components/Offers/Offer-Details/OfferDetails"

const OfferDetailsPage = () => {

    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: "100%" }}>
         <OfferDetails/>
        </div>
      </div>
    )
}

export default OfferDetailsPage