import React, { useEffect, useState } from "react";

import favoff from "../../Images/fav-off.png";
import favon from "../../Images/fav-on.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendors } from "../../redux/actions/usersActions";

import notify from "../../hook/useNotification";


import {
  getAllCategory,
  getAllCategoriesWithoutBlockedCategory,
  getAllCategories,
} from "../../redux/actions/categoryAction";




import { event } from "react-ga";
import { addProductsFromShopify, getAllShopifyProducts } from "../../redux/actions/shopifyActions";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { getAllBrand } from "../../redux/actions/brandAction";
import { useTranslation } from "react-i18next";




const ExportProductsFromDropShippingHook = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  //const[storedProducts,setStoredProducts]=useState();
  const [categoryId, setCategoryId] = useState();
  //const[categories,setCategories]=useState();
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [selectedsubId, setSelectedsubId] = useState();
  const [brandId, setBrandId] = useState("");
  const [vedorId, setvedorId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const[showTable,setShowTable]=useState(false);
  const[ids,setIds]=useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const settingsRes = useSelector((state) => state.settingsReducers.settings);


  useEffect(() => {
    dispatch(getAllVendors());
   // dispatch(getAllShopifyProducts());
    dispatch(getAllCategories());
    dispatch(getAllBrand());
    dispatch(getAllVendors());

  }, [dispatch]);

  const handleClick=async ()=>{
    await dispatch(getAllShopifyProducts(ids));
    setShowTable(true);
  }
  // useEffect(()=>{
  //   console.log('ids');
  //   console.log(ids);
  //   dispatch(getAllShopifyProducts(ids));
  // },[ids]);

  const dropShippingDataResponse = useSelector((state) => state.ShopifyReducer.shopifyProducts);
  const isloading=useSelector((state)=>state.ShopifyReducer.isLoading);
  const isAdding=useSelector((state)=>state.ShopifyReducer.isAdding);

  console.log('dropShippingDataResponse');
  console.log(dropShippingDataResponse);


  useEffect(() => {
    if (dropShippingDataResponse?.products) setProducts(dropShippingDataResponse.products);
  }, [dropShippingDataResponse]);



  const category = useSelector((state) => state.allCategory.categories);

  console.log('category');
  console.log(category);


 

  const handleRowSelected = (state) => {
    console.log('state.selectedRows');
    console.log(state.selectedRows);
    setSelectedRows(state.selectedRows);
  }

  const showModal = () => {
    //selectedRows
    // console.log(selectedRows);
    if (selectedRows.length == 0) notify(t("select at least One Item"), 'error');

    else setShowAddProductModal(true);
  }

  const onSelectCategory = async (value) => {
    if (value !== null) {
      await dispatch(subCategoryByCatId(value._id));
    }
    if (value == null) setSelectedsubId(null);
    setCategoryId(value);
  }

  const onSelectSubCategory = (value) => {
    setSelectedsubId(value);
  }

  const onSelectBrand = (value) => {
    setBrandId(value);
  }

  const brand = useSelector((state) => state.allBrand.brand);

  const subCategory = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );

  const onChangeVendor = (value) => {
    setvedorId(value);
  }

  const getAllUser = useSelector((state) => state.usersReducers.allVendors);



  const saveProducts = async () => {
    if(!categoryId ||!selectedsubId  ||!vedorId) return notify(t("please complete the data"),"error");

    console.log('selectedRows');
    console.log(selectedRows);

    
    
    let productsObj={
      products: selectedRows,
      category: categoryId,
      subcategory : selectedsubId,
      brandId,
      vedorId
    }

    await dispatch(addProductsFromShopify(productsObj));
   
  };

  const addProductsResponse=useSelector((state)=>state.ShopifyReducer.addProducts);

  useEffect(()=>{
    if(addProductsResponse){

                    
      notify(t("Added successfully"),"success");
      
      setShowAddProductModal(false);
      setCategoryId();
      setSelectedsubId();
      setBrandId();
      setvedorId();
      // console.log('dddddddddddddddddddddddddddddddddddddd');
      // console.log(products.filter(el=> !selectedRows.find(ele=> ele.id===el.id)));
      setProducts(products.filter(el=> !selectedRows.find(ele=> ele.id===el.id)));
      setSelectedRows([]);
    }
    else  notify(t("Failed in the Adding process"),"warn");
  },[addProductsResponse]);

  return [
    products,
    handleRowSelected,
    settingsRes,
    showAddProductModal,
    setShowAddProductModal,
    categoryId,
    onSelectCategory,
    category,
    selectedsubId,
    subCategory,
    onSelectSubCategory,
    brandId,
    onSelectBrand,
    brand,
    vedorId,
    onChangeVendor,
    getAllUser,
    showModal,
    saveProducts,
    isloading,
    isAdding,
    selectedRows,
    showTable,
    setShowTable,
    setIds,
    handleClick
  ];
};

export default ExportProductsFromDropShippingHook;
