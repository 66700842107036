import AdminProductReviews from "../../Components/Admin/AdminProductReviews"
import SideBar from "../../Components/Admin/SideBar"

const AdminProductReviewsPage = () => {

    return (
        <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
      <AdminProductReviews/>

      </div>
    </div>
    )

}

export default AdminProductReviewsPage