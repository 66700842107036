import React, { useEffect, useState } from "react";
import { createCategory, getAllCategoriesByAdmin, updateCategory } from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { getAllCategories } from "../../redux/actions/categoryAction";
import { deleteCategory } from "../../redux/actions/categoryAction";
import { useLocation, useNavigate } from "react-router-dom";
import {  getSettings} from "../../redux/actions/settingsAcions";



const AddCatHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);


  const [show,setShow] = useState(false)
  const [categoryId,setCategryId] = useState("")
  const handleClose = () => setShow(false)
  const handleShow = (id) => {
    setCategryId(id)
    setShow(true)
  }

  const handleDelete = async () => {
    await dispatch(deleteCategory(categoryId));
   notify("تم الحذف بنجاح", "success");
   setShow(false);
        setTimeout(() => {
          // navigator('/admin/categories')
          window.location.reload(false);
        }, 1000);

  };

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateCategory(id, {
        status: status,
      })
    );
    setLoading(false);
  };

    const categoryRes = useSelector((state)=>state.allCategory.updateCategory)





  const clickHandler = (id) => {
     navigate(`/admin/editCategory/${id}`);
  };

 




  const res = useSelector((state) => state.allCategory.category);
  
  

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    if (nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");

      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    setIsPress(true);
    await dispatch(createCategory(formData));
    setLoading(false);
  };

  const location = useLocation();

  console.log('location.pathname');
  console.log(location.pathname);
  useEffect(()=>{
    if(location.pathname=='/admin/categories')
    dispatch(getAllCategoriesByAdmin());
    else
    dispatch(getAllCategories())

  },[]);

  let categories =  useSelector((state)=>
  location.pathname=='/admin/categories'? state.allCategory.getCategoriesByAdmin :state.allCategory.categories
  );

  console.log('categories');
  console.log(categories);

  categories=categories?.filter(el=>el.isChild==false);

   console.log('categories after');
  console.log(categories);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);


  useEffect(() => {
    if (loading === false) {

      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setSelectedFile(null);
      setLoading(true);
      setTimeout(() => setIsPress(false), 1000);

      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      } else if (categoryRes){
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllCategories())
        }, 1000);
      } 
      else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);
  return [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    categories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes
    
  ];
};

export default AddCatHook;
