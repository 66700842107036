import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import store from "./redux/store";
import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';





const root = ReactDOM.createRoot(document.getElementById("root"));



const initializeApp=async()=>{
 // const apiData = await fetchDataFromAPI();

  // console.log('apiData');
  // console.log(apiData);

  root.render(
    <HelmetProvider>
    <Provider store={store}>
      <App  />
    </Provider>
    </HelmetProvider>
  );
}


initializeApp();
