import {
  ALL_BACKUP_LOGS,
  BACKUP,
  DELETE_BACKUP_LOG,
  DOWNLOAD_BACKUP,
  RESTORE_BACKUP_FILE,
  RESTORE_BACKUP_LOG,
} from "../type";

const initialState = {
  backup: [],
  allBackupLogs: [],
  deleteBackupLog: [],
  downloadBackup: [],
  restoreBackupLog: [],
  restoreImportFile: [],
};

const backupRestoreResducer = (state = initialState, action) => {
  switch (action.type) {
    case BACKUP:
      return {
        ...state,
        backup: action.payload,
      };
    case ALL_BACKUP_LOGS:
      return {
        ...state,
        allBackupLogs: action.payload,
      };
    case DELETE_BACKUP_LOG:
      return {
        ...state,
        deleteBackupLog: action.payload,
      };
    case DOWNLOAD_BACKUP:
      return {
        ...state,
        downloadBackup: action.payload,
      };
    case RESTORE_BACKUP_LOG:
      return {
        ...state,
        restoreBackupLog: action.payload,
      };
    case RESTORE_BACKUP_FILE:
      return {
        ...state,
        restoreImportFile: action.payload,
      };
    default:
      return state;
  }
};

export default backupRestoreResducer;
