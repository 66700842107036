import React from "react";
import { useParams } from "react-router-dom";
import AdminEditShippingHook from '../../hook/admin/AdminEditShippingHook'
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

const AdminEditDropShipping = () => {
  const { id } = useParams();
  const {t} = useTranslation()
  const [values,onChangeValues,settingsRes,onSubmit]  = AdminEditShippingHook(id)


  return (
    <div>
    <Row className="justify-content-start ">
      <div className="admin-content-text pb-4"> {t("Edit DropShipping")} </div>
      <Col sm="8">
        <label htmlFor="nameAr" className="d-block mt-2">{t("name Ar")}</label>
        <input
          id="nameAr"
          name="nameAr"
          value={values.nameAr}
          onChange={onChangeValues}
          type="text"
          className="input-form d-block mt-2 px-3"
        />

        <label htmlFor="nameEn" className="d-block mt-2">{t("name En")}</label>
        <input
        name="nameEn"
          id="nameEn"
          value={values.nameEn}
          onChange={onChangeValues}
          type="text"
          className="input-form d-block mt-2 px-3"
        />

        <label htmlFor="ProfitRatio" className="d-block mt-2">{t("Profit Ratio")}</label>
        <input
          id="ProfitRatio"
          name="profitRatio"
          value={values.profitRatio}
          onChange={onChangeValues}
          type="number"
          className="input-form d-block mt-2 px-3"
        />
        <label htmlFor="Conversionfactor" className="d-block mt-2">{t("Conversion factor")}</label>
        <input
          id="Conversionfactor"
          value={values.currencyFactor}
          onChange={onChangeValues}
          type="number"
          name="currencyFactor"
          className="input-form d-block mt-2 px-3"
        />
        <label htmlFor="code" className="d-block mt-2">{t("code")}</label>
        <input
        disabled={true}
          id="code"
          name="key"
          value={values.key}
          onChange={onChangeValues}
          type="number"
          className="input-form d-block mt-2 px-3"
        />
     
      </Col>
    </Row>
    <Row>
      <Col sm="8" className="d-flex justify-content-end ">
        <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
          {t("save")}
        </button>
      </Col>
    </Row>
    <ToastContainer />
  </div>
  )
};

export default AdminEditDropShipping;
