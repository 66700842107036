import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import { getWebsiteData , getSettings } from "../../redux/actions/settingsAcions";
import { getAllSlider } from "../../redux/actions/sliderActions";

const AdmiViewSliderHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllSlider());
    }, []);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);
    const allSliders = useSelector((state) => state.sliderReducer.allSlider);

    let slider = [];
    if (allSliders) slider = allSliders;

    const clickHandler = (id) => {
        navigate(`/admin/editsliders/${id}`);
    };

    return [slider, clickHandler , settingsRes];
};

export default AdmiViewSliderHook;
