import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import SideBar from "../../Components/Admin/SideBar";
import EditPaymentMethod from "../../Components/Checkout/EditPaymentMethod";

const EditPaymentMethodPage = () => {
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-4" style={{ width: '100%' }}>
      <EditPaymentMethod/>
    </div>
  </div>

  );
};

export default EditPaymentMethodPage;

