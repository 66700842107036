import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

function SkuTable({tableSizes,deleteElement,keys,headers}) {
    const { t } = useTranslation();
    console.log('table');
    console.log(tableSizes);
    
    return (
        <Table
            striped
            bordered
            hover
            className="mt-3 mx-5"
            style={{ overflow:'auto' }}
        >
            <thead>
                <tr>
                    {
                        headers.map((header,index)=>(
                            <th key={header}>{header}</th>
                        ))
                    }
                    {/* <th>#</th>
                    <th>{t("Title name")} </th>
                    <th>{t("price")} </th>
                    <th>{t("sku")} </th>
                    <th>{t("Quantity")}</th>
                    <th>{t("delete")} </th> */}
                </tr>
            </thead>
            <tbody>
                {tableSizes
                    ? tableSizes.map((el, index) => (
                        <tr key={index}>
                            <td >{index + 1}</td>
                            {
                                keys.map((e)=>(
                                    <td key={Math.random()} style={{overflowWrap:'anywhere'}}>{el[e]?.toString()}</td>
                                )
                                
                                )
                            }
                            <td key={Math.random()}>
                                <i
                                    onClick={() => deleteElement(index)}
                                    className="fa-regular fa-trash-can"
                                ></i>
                            </td>
                            
                        </tr>
                    ))
                    : ""}
            </tbody>
        </Table>
    )
}

export default SkuTable