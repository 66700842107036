import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RegisterHook from "../../hook/auth/RegisterHook";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const RegisterPage = () => {
  const [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    acceptTerms,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeAcceptTerms,
    onSubmit,
  ] = RegisterHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <Container style={{ minHeight: "670px" }}>
      <Row className="py-5 d-flex justify-content-center hieght-search">
        <Col sm="12" className="d-flex flex-column ">
          <label className="mx-auto title-login">تسجيل حساب جديد</label>
          <input
            value={firstname}
            onChange={onChangeFirstname}
            placeholder="الاسم الاول..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={lastname}
            onChange={onChangeLastname}
            placeholder="الاسم الاخير..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />

          <input
            value={email}
            onChange={onChangeEmail}
            placeholder="الايميل..."
            type="email"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={mobile}
            onChange={onChangeMobile}
            placeholder="الهاتف..."
            type="phone"
            className="user-input my-2 text-center mx-auto"
          />

          <input
            value={password}
            onChange={onChangePassword}
            placeholder="كلمه السر..."
            type="password"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            placeholder="تاكيد كلمة السر ..."
            type="password"
            className="user-input my-2 text-center mx-auto"
          />

          <div className="my-2 text-center mx-auto">
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={onChangeAcceptTerms}
            />
            <Link
              to={`/terms/${1}`}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              أوافق على الشروط والأحكام
            </Link>
          </div>

          <button
            onClick={onSubmit}
            className="btn-login mx-auto mt-4"
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            تسجيل الحساب
          </button>
          <label className="mx-auto my-4">
            لديك حساب بالفعل؟
            <Link to="/login" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer" }} className="text-danger">
                اضغط هنا
              </span>
            </Link>
          </label>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default RegisterPage;
