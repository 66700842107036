import {
    GET_ALI_EXPRESS_ACCESS_TOKEN,GET_PRODUCTS_FROM_ALI_EXPRESS,GET_ALI_EXPRESS_CATEGORIES,GETTING_PRODUCTS,GET_ALL_PRODUCTS_ALI_EXPRESS_ARRAY
  } from "../type";
  
  const inital = {
     access_token: '',
     aliExpressProducts: [],
     aliExpressStoredProducts:[],
     aliExpressCategories:[],
     isLoading:false
  };

  const AliExpressReducer=(state=inital,action)=>{
      switch(action.type)
      {
        case GET_ALI_EXPRESS_ACCESS_TOKEN:
          return{
            ...state,
            access_token:action.payload
          };

        case GET_PRODUCTS_FROM_ALI_EXPRESS:
          return{
            ...state,
            aliExpressProducts:action.payload
          }
        case GET_ALI_EXPRESS_CATEGORIES:
          return{
            ...state,
            aliExpressCategories:action.payload,
            isLoading:false
          }
        case GETTING_PRODUCTS:
          return{
            ...state,
            isLoading:true
          };
          case GET_ALL_PRODUCTS_ALI_EXPRESS_ARRAY:
            return{
              ...state,
              aliExpressStoredProducts:state.aliExpressProducts
            }
            
        default:
            return state;
      }
  }

  export default AliExpressReducer;