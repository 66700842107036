import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneCategory } from "../../redux/actions/categoryAction";
import {
  getAllProducts,
  getProductsByCategories,
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useParams } from "react-router-dom";


const ViewAllProductsByCategoryHook = (catId) => {
  let limit = 8;

  const { id } = useParams();

  const dispatch = useDispatch();
  const getProduct = async () => {
    await dispatch(getProductsByCategories(id, limit, 1));
  };
  const getById = async () => {
    await dispatch(getOneCategory(id));
  };

  useEffect(() => {
    getProduct();
    getById();
    window.scrollTo(0, 0);
    
  }, [id]);

  const allproduct = useSelector(
    (state) => state.allProduct.productsByCategory
  );
  const catById = useSelector((state) => state.allCategory.oneCategory);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onPress = async (page) => {
    await dispatch(getProductsByCategories(catId, limit, page));
  };

  let items = [];
  let pagination = [];

  try {
    if (allproduct)
      if (allproduct.data) items = allproduct.data;
      else items = [];
  } catch (error) {}
  try {
    if (allproduct)
      if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
      else pagination = [];
  } catch (error) {}

  return [items, pagination, onPress, catById  , settingsRes];
};

export default ViewAllProductsByCategoryHook;
