import Cookies from 'js-cookie'
import React from 'react'

function CustomSelect({ selectRef, object, id, defaultOption, type }) {

    if (type == "ref") {
        return (
            <select
                id={id}
                className="input-form w-75"
                ref={selectRef}
            >
                <option value='' >{defaultOption}</option>
                {
                    object && object.map(el =>
                        <option value={el.key}>
                            {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
                        </option>
                    )


                }
            </select>
        )
    }


}

export default CustomSelect