import {
  CREATE_NEW_USER,
  LOGIN_USER,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  FORGET_PASSWORD_EMAIL_CODE,
  RESET_PASSWORD,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const createNewUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/users/register`, data);

    dispatch({
      type: CREATE_NEW_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_USER,
      payload: error.response,
    });
  }
};

export const loginUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/users/login`, data);

    dispatch({
      type: LOGIN_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_USER,
      payload: error.response,
    });
  }
};

export const updateUserProfile = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/users/${id}`, body);

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: error.response,
    });
  }
};

export const updateUserPassword = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/users/${id}`, body);

    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: error.response,
    });
  }
};

// forget passord post email or phone to send code to email
export const forgetPasswordEmailCode = (body) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/forgetPassword", body);
    dispatch({
      type: FORGET_PASSWORD_EMAIL_CODE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FORGET_PASSWORD_EMAIL_CODE,
      payload: error.response,
    });
  }
};

// reset password
export const resetPassword = (val, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/forgetPassword/${val}`, body);
    dispatch({
      type: RESET_PASSWORD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD,
      payload: error.response,
    });
  }
};
