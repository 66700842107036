import { GENERATE_UTTO_LINK } from "../type";


const initial={
    uttoPaymentUrl:null,
    uttoPaymentResponse:null
};
const uttoReducer=(state=initial,action)=>{
    switch (action.type) {
        case GENERATE_UTTO_LINK:
            return{
                ...state,
                uttoPaymentUrl:action.payload,
                uttoPaymentResponse:null
            }
            
    
        default:
            return state;
    }
}

export default uttoReducer;