import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import AdminViewAdsHook from "../../hook/admin/AdminViewAdsHook";
import { getAllAds, updateAds } from "../../redux/actions/adsAction";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AdminAds = () => {
  const { t } = useTranslation();
  const [
    ads,
    clickHandler,
    settingsRes,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    deleteAdss,
  ] = AdminViewAdsHook();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateAds(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.sliderReducer.updateSlider);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify(t("Modified successfully"), "success");
        setTimeout(() => {
          dispatch(getAllAds());
        }, 1500);
      } else {
        notify(t("Failed in the editing process"), "error");
      }
    }
  }, [loading]);

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        <>
          <img
            alt="imageAds"
            src={row.images[0]}
            width="50px"
            height="50px"
            style={{ padding: "3px", objectFit: "contain" }}
          />{" "}
          {row.images[1] && (
            <img
              alt="imageAds"
              src={row.images[1]}
              width="50px"
              height="50px"
              style={{ padding: "3px", objectFit: "contain" }}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("address"),
      selector: "title",
      sortable: true,
    },

    {
      name: t("page"),
      selector: "page",
      sortable: true,
    },
    {
      name: t("background color"),
      cell: (row) => (
        <>
          <div
            className="color ms-2 border  mt-1"
            style={{ backgroundColor: row.color }}
          ></div>
          <div
            className="color ms-2 border  mt-1"
            style={{ backgroundColor: row.secondaryColor }}
          ></div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status === true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("delete"),
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => showDeleteModal(row._id)}
          id={row._id}
        >
          {t("delete")}
          <i className="fa fa-trash mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: ads,
  };

  return (
    <div>
      {/********** Modal To Delete The Offer From The Product *****************/}
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeDeleteModal}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={deleteAdss}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/************************************************************************/}

      <Helmet>
        <title>
          {`${t("ads")}-  ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("Advertising Management")}</div>
        <AddButton title={t("add ads")} navigateTo="/admin/addAds" />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search her")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminAds;
