import React from "react";
import PayMethodsAdmin from "../../Components/Checkout/PayMethodsAdmin";
import { Container } from "react-bootstrap";
import SideBar from "../../Components/Admin/SideBar";
import AdminShippinMethods from "../../Components/Admin/AdminShippinMethods";

const AdminShippingMethodPage = () => {
    return (

        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminShippinMethods />
            </div>
        </div>



    )
}

export default AdminShippingMethodPage

