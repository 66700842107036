import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import OrderPayCashHook from "../../hook/checkout/AddPaymentMethodHook";
import AddPaymentMethodHook from "../../hook/checkout/AddPaymentMethodHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import PaymentMethodCard from "../Checkout/PaymentMethodCard";
import AddShippingHook from "../../hook/admin/AddShippingHook";
import addSetting from "../../Images/add-setting.png"
import { Helmet } from "react-helmet";
import AdminShipingCard from "./AdminShipingCard";


const AdminShippinMethods = () => {
  const { t } = useTranslation();
  const [
    paymentMethodName_Ar,
    paymentMethodName_En,
    onChangePayMethodName_Ar,
    onChangePayMethodName_En,
    allPaymentMethods,
    onSubmit,
    key,
    onChangeKey,
    value,
    onChangeValue,
    tablekeysValues,
    clickHandler,
    show,
    handleShow,
    deleteElement,
    settingsRes,
    wieghtNumber,
    wieghtCost,
    wieghtExtraCost,
    onChangewieghtNumber,
    onChangewieghtCost,
    onChangewieghtExtraCost

  ] = AddShippingHook();


  return (
    <div>
      <Helmet>
        <title>{` ${t("Shipping Methods")}  -${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>

      <Row className="justify-content-start ">
        <div className="admin-content-text py-3">{t("Add a new shipping method")}   </div>
        <Col sm="8">
          <input
            value={paymentMethodName_Ar}
            onChange={onChangePayMethodName_Ar}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("The name of the shipping method is in Arabic")}
          />
          <input
            value={paymentMethodName_En}
            onChange={onChangePayMethodName_En}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("The name of the shipping method is in english")}
          />

          <input
            value={wieghtNumber}
            onChange={onChangewieghtNumber}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" عدد الوزن  "
          />
          <input
            value={wieghtCost}
            onChange={onChangewieghtCost}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" تكلفة الوزن  "
          />
          <input
            value={wieghtExtraCost}
            onChange={onChangewieghtExtraCost}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder=" تكلفة الوزن الاضافيه  "
          />

          <label className="mt-2" htmlFor="setting" ><strong>{t("Add settings")} </strong></label>
          <div>
            <img
              id='setting'

              src={addSetting}
              alt=""
              width="50px"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow()}
            />

          </div>
          {show ? <div>
            <input
              style={{
                borderColor: "gray",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
              }}
              value={key}
              onChange={onChangeKey}
              className="d-inline-block  mt-2 p-1 "
              placeholder="key"
            />
            <input
              style={{
                borderColor: "gray",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
              }}
              value={value}
              onChange={onChangeValue}
              className="d-inline-block mx-3 mt-2 p-1 "
              placeholder="value"
            />
            <button
              onClick={() => clickHandler()}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("add")}
            </button>
          </div> : ''}


        </Col>
        {show ? <Col sm-8>
          <Table striped bordered hover className="mt-3 mx-5 w-50" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>#</th>
                <th> {t("Key")}</th>
                <th colSpan='2'>{t("Value")} </th>
              </tr>
            </thead>
            <tbody>
              {
                tablekeysValues ? tablekeysValues.map(el => (
                  <tr key={el.count}>
                    <td>{el.count}</td>
                    <td>{el.key}</td>
                    <td >{el.value}</td>
                    <td><i onClick={() => deleteElement(el.count)} className="fa-regular fa-trash-can"></i></td>


                  </tr>
                )) : ''
              }

            </tbody>
          </Table>
        </Col> : ''}

      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end mt-2 ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          {allPaymentMethods &&
            allPaymentMethods.map((el) => (
              <AdminShipingCard key={el._id} shippingMethod={el} />

            ))}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminShippinMethods;
