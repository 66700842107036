import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditCoupon from "../../Components/Admin/AdminEditCoupon";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditCouponPage = () => {
  return (
    // <Container className="py-3">
    //   <Row className="py-3">
    //     <Col sm="3" xs="2" md="2">
    //       <SideBar />
    //     </Col>
    //     <Col sm="9" xs="10" md="10">
    //       <AdminEditCoupon />
    //     </Col>
    //   </Row>
    // </Container>
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminEditCoupon />
      </div>
    </div>

  );
};

export default AdminEditCouponPage;
