import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminAddAds from "../../Components/Admin/AdminAddAds";

const AdminAddAdsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminAddAds />
      </div>
    </div>
  );
};

export default AdminAddAdsPage;
