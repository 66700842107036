import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrandById } from "../../redux/actions/brandAction";
import { getProductsByBrand } from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";


const ViewAllProductByBrandHook = (id) => {

    let limit = 8;
    const dispatch = useDispatch();
    const getProduct = async () => {
        await dispatch(
            getProductsByBrand(id, limit, 1)
        );
    };
    const getById = async () => {
        await dispatch(
            getBrandById(id)
        );
    };

    useEffect(() => {
        getProduct();
        getById();
        window.scrollTo(0, 0);
    }, []);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    const allproduct = useSelector((state) => state.allProduct.productsByBrand);
    const brandById = useSelector((state) => state.allBrand.brandById);

    const onPress = async (page) => {
        await dispatch(
            getProductsByBrand(id, limit, page)
        );
    };

    let items = [];
    let pagination = [];

    try {
        if (allproduct)
            if (allproduct.data) items = allproduct.data;
            else items = [];
    } catch (error) { }
    try {
        if (allproduct)
            if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
            else pagination = [];
    } catch (error) { }

    return [items, pagination, onPress, brandById , settingsRes]
};

export default ViewAllProductByBrandHook;
