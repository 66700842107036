import { CREATE_NEW_ADS, GET_ALL_ADS, DELETE_ADS, UPDATE_ADS, GET_ONE_ADS, GET_ALL_ADS_WEB_SITE } from "../type";
const inital = {
    newAds: [],
    allAds: [],
    allAdsWebsite: [],
    updateAds: [],
    oneAds: [],

};

const adsReducers = (state = inital, action) => {
    switch (action.type) {
        case CREATE_NEW_ADS:
            return {
                ...state,
                newAds: action.payload,
            };
        case GET_ALL_ADS:
            return {
                ...state,
                allAds: action.payload,
            };
        case GET_ALL_ADS_WEB_SITE:
            return {
                ...state,
                allAdsWebsite: action.payload,
            };
        case UPDATE_ADS:
            return {
                ...state,
                updateAds: action.payload,
            };
        case GET_ONE_ADS:
            return {
                ...state,
                oneAds: action.payload,
            };
        default:
            return state;
    }
};
export default adsReducers;
