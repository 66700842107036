import React from "react";
import { Row, Col, Spinner, FormLabel, Tabs, Tab, Table, Form } from "react-bootstrap";
import add from "../../Images/add.png";
import { useParams } from "react-router-dom";
import MultiImageInput from "react-multiple-image-input";
import { CompactPicker } from "react-color";
import { ToastContainer } from "react-toastify";
import EditProductHook from "../../hook/product/EditProductHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar'
import Select from 'react-select';
import SearchByTypingSelect from "../Utilities/SearchByTypingSelect";
import SkuTable from "../Utilities/SkuTable";
import AddVariantsAndSkus from "../Utilities/AddVariantsAndSkus";


const AdminEditProduct = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    categoryId,
    brandId,
    subCategory,
    selectedsubId,
    isFeatured,
    images,
    nameAr,
    nameEn,
    descriptionAr,
    descriptionEn,
    richDescriptionAr,
    richDescriptionEn,
    price1,
    price2,
    price3,
    price4,
    countInStock,
    showPicker,
    colors,
    category,
    brand,
    onSelectSubCategory,
    selectedFeatured,
    onSelectBrand,
    handleChaneComplete,
    onSelectCategory,
    removeColor,
    handleSubmit,
    setImages,
    onChangeNameAr,
    onChangeNameEn,
    onChangeDescriptionAr,
    onChangeDescriptionEn,
    onChangeRichDescriptionAr,
    onChangeRichDescriptionEn,
    onChangePrice1,
    onChangePrice2,
    onChangePrice3,
    onChangePrice4,
    onChangeCountInStock,
    onChangePicker,
    loading,
    check,
    descriptionProduct,
    keywordsProducts,
    onChangeDescriptionProduct,
    onChangeKeywordsProducts,
    ProductStatus,
    onChangeProductStatus,
    weight,
    onChangeWeight,
    ref,
    onChangeVideo,
    video,
    value,
    tableSizes,
    onChangeValue,
    clickHandler,
    deleteElement,
    categoryObject,
    subCategoryObject,
    dropShippingType,
    productPriceDropShipping,
    productCodeDropShipping,
    updateProductPriceDropShipping,
    onChangeDropShipping,
    onChangeProductPriceDropShipping,
    onChangeProductCodeDropShipping,
    onChangeUpdateProductPriceDropShipping,
    dropShippingsResponse,
    vendor,
    allVendors,
    onChangeVendor,
    user,
    deleteSkuElement,
tableSkuSizes,
settableSkuSizes,
hasChildClassifications,
onChangeHasChildClassifications,
DropShippingCode,
onChangeDropShippingCode

  ] = EditProductHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);


  console.log('table skus');
  console.log(tableSkuSizes);
  // console.log("user");
  // console.log(user);
  // console.log('category');
  // console.log(category);

  // console.log('categoryObject');
  // console.log(categoryObject);


  return (
    <div>
      <Row className="justify-content-start ">
        <LoadingBar color='red' ref={ref} shadow={true} height={4} />

        <div className="admin-content-text pb-4"> {t("Product modification")} </div>

        <Tabs
          defaultActiveKey="name"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="name"
            title={t("product")}
            style={{ backgroundColor: "white" }}>

            <label htmlFor="nameAr" className=" d-block mt-2 ">
              {t("product name in arabic")}
            </label>
            <input
              id="nameAr"
              onChange={onChangeNameAr}
              value={nameAr}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("product name in arabic")}
            />
            <label htmlFor="nameEn" className=" d-block mt-2 ">
              {t("product name in english")}
            </label>
            <input
              id="nameEn"
              onChange={onChangeNameEn}
              value={nameEn}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("product name in english")}
            />

            <label htmlFor="categories" className=" d-block mt-2 ">
              {t("main category")}
            </label>


            <Select
              isClearable={true}
              defaultOptions
              onChange={onSelectCategory}
              value={categoryObject}
              getOptionLabel={(el) => Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
              getOptionValue={(e) => e._id}
              placeholder={t("main classification")}
              options={category}
            />

            <label htmlFor="subCategory" className=" d-block mt-2 ">
              {t("Subcategory")}
            </label>



            <Select
              isClearable={true}
              defaultOptions
              onChange={onSelectSubCategory}
              value={subCategoryObject}
              getOptionLabel={(el) => Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
              getOptionValue={(e) => e._id}
              placeholder={t("Subcategory")}
              options={subCategory}
            />

            {
              user.role === "admin" &&
              <>
                <label htmlFor="vendor name" className="d-block mt-2">
                  {t("vendor name")}
                </label>
                <SearchByTypingSelect
                  value={vendor}
                  onChange={onChangeVendor}
                  getOptionLabel={(el) => `${el?.firstname} ${el?.lastname}`}
                  getOptionValue={(e) => e._id}
                  options={allVendors}
                  placeholder={t("vendor name")}
                />
              </>
            }



            <label htmlFor="brand" className=" d-block mt-2 ">
              {t("brand")}
            </label>
            <select
              id="brand"
              value={brandId}
              name="brand"
              onChange={onSelectBrand}
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("select category")}</option>
              {brand.data
                ? brand.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}

                    </option>
                  );
                })
                : null}
            </select>

            <label htmlFor="amount" className=" d-block mt-2 ">
              {t("Available quantity")}
            </label>
            <input
              id="amount"
              onChange={onChangeCountInStock}
              value={countInStock}
              type="number"
              className="input-form d-block mt-3 px-3"
              placeholder={t("Available quantity")}
            />

            <label htmlFor="DropShippingCode" className="d-block mt-2" >
              {t("DropShippingCode")}
            </label>

            <input
            id="DropShippingCode"
            value={DropShippingCode}
            onChange={onChangeDropShippingCode}
            className="input-form d-block mt-2 px-3"
            placeholder={t("DropShippingCode")}

             />
            <label htmlFor="statusProduct" className="d-block mt-2 ">
              {" "}
              {t("product status")}{" "}
            </label>
            <select
              id="statusProduct"
              name="status"
              value={ProductStatus}
              onChange={onChangeProductStatus}
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("product status")}</option>
              <option value="true">{t("Show")}</option>
              <option value="false">{t("hidden")}</option>
            </select>
          </Tab>
          <Tab
            eventKey="seo"
            title="  SEO"
            style={{ backgroundColor: "white" }}
          >

            <label htmlFor="descriptionProduct" className="d-block mt-2 ">{t("Product Description")} </label>
            <textarea
              onChange={onChangeDescriptionProduct}
              value={descriptionProduct}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="descriptionProduct"
              placeholder={t("Product Description")}
            />

            <label htmlFor="keywordsProducts" className="d-block mt-2 ">{t("Product keywords")}  {" "}</label>
            <textarea
              onChange={onChangeKeywordsProducts}
              value={keywordsProducts}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="keywordsProducts"
              placeholder={t("Product keywords")}
            />
          </Tab>

          <Tab
            eventKey="desc"
            title={t("price")}
            style={{ backgroundColor: "white" }}
          >

            <label htmlFor="price1" className=" d-block mt-2 ">
              {t("first price")}
            </label>
            <input
              id="price1"
              onChange={onChangePrice1}
              value={price1}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("first price")}
            />
            <label htmlFor="price2" className=" d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price2"
              onChange={onChangePrice2}
              value={price2}
              type="number"
              className="input-form d-block mt-2 px-2"
              placeholder={t("extra price")}
            />
            <label htmlFor="price3" className=" d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price3"
              onChange={onChangePrice3}
              value={price3}
              type="number"
              className="input-form d-block mt-2 px-2"
              placeholder={t("extra price")}
            />
            <label htmlFor="price4" className=" d-block mt-2 ">
              {t("extra price")}
            </label>
            <input
              id="price4"
              onChange={onChangePrice4}
              value={price4}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("extra price")}
            />
            <label htmlFor="weight" className="d-block mt-2 ">

              {t("weight")}
            </label>
            <input
              id="weight"
              onChange={onChangeWeight}
              value={weight}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("weight")}
            />
          </Tab>

          <Tab
            eventKey="productDescription"
            title={t("Product Description")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="detailAr" className=" d-block mt-4 mb-2 ">
              {t("Product Description in arabic")}
            </label>
            <textarea
              id="detailAr"
              onChange={onChangeDescriptionAr}
              value={descriptionAr}
              className="input-form-area p-2 mt-2"
              rows="4"
              cols="50"
              placeholder={t("Product Description in arabic")}
            />
            <label htmlFor="detailEr" className=" d-block mt-2">
              {t("Product Description in english")}
            </label>
            <textarea
              id="detailEr"
              onChange={onChangeDescriptionEn}
              value={descriptionEn}
              className="input-form-area p-2 mt-2"
              rows="4"
              cols="50"
              placeholder={t("Product Description in english")}
            />
            <label htmlFor="productDetailAr" className=" d-block mt-2  ">
              {t("product properties in arabic")}
            </label>
            <textarea
              id="productDetailAr"
              onChange={onChangeRichDescriptionAr}
              value={richDescriptionAr}
              className="input-form-area p-2 mt-2"
              rows="4"
              cols="50"
              placeholder={t("product properties in arabic")}
            />
            <label htmlFor="productDetailEn" className=" d-block mt-2  ">
              {t("product properties in english")}
            </label>

            <textarea
              id="productDetailEn"
              onChange={onChangeRichDescriptionEn}
              value={richDescriptionEn}
              className="input-form-area p-2 mt-3"
              rows="4"
              cols="50"
              placeholder={t("product properties in english")}
            />
            <label htmlFor="featured" className=" d-block mt-2  ">
              {" "}
              {t("Featured Product")}{" "}
            </label>
            <select
              value={isFeatured}
              name="featured"
              onChange={selectedFeatured}
              id="featured"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="false">{t("no")}</option>
              <option value="true">{t("yes")}</option>
            </select>


          </Tab>

          <Tab
            eventKey="colors"
            title={t("Colors and sizes")}
            style={{ backgroundColor: "white" }}
          >

            <div className="text-form mt-3 "> {t("Available colors for the product")}</div>
            <div className="mt-1 d-flex">
              {colors.length >= 1
                ? colors.map((c, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => removeColor(c)}
                      className="color ms-2 border  mt-1"
                      style={{ backgroundColor: c }}
                    ></div>
                  );
                })
                : null}

              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePicker}
              />

              {showPicker === true ? (
                <CompactPicker onChangeComplete={handleChaneComplete} />
              ) : null}
            </div>
            <label htmlFor="sizes" className="d-block mt-3 ">
              {t("Available sizes")}
            </label>

            <div>
              <input
                style={{
                  borderColor: "gray",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderRadius: "6px",
                }}
                value={value}
                onChange={onChangeValue}
                className="d-inline-block mx-3 mt-2 p-1 "
                placeholder="value"
              />
              <button
                onClick={() => clickHandler()}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
              >
                {t("add")}
              </button>
            </div>
            <Col sm-8>
              <Table striped bordered hover className="mt-3 mx-5 w-50" style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("size")} </th>
                    <th>{t("delete")} </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    tableSizes ? tableSizes.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td >{el}</td>
                        <td><i onClick={() => deleteElement(index)} className="fa-regular fa-trash-can"></i></td>


                      </tr>
                    )) : ''
                  }

                </tbody>
              </Table>
            </Col>




          </Tab>

          <Tab
           eventKey="classification"
           title={t("classification")}
           style={{ backgroundColor: "white" }}
          >

            <label htmlFor="have child classifications" className="d-block mt-2 ">
              {t("have child classifications")}
            </label>

            <select
            id="have child classifications"
            className="select input-form-area mt-2 px-2"
            value={hasChildClassifications}
            onChange={onChangeHasChildClassifications}
            >
              <option value={false}>{t("no")}</option>
              <option value={true}>{t("yes")}</option>
            </select>

            {
              hasChildClassifications=="true"&&(
                <>
                <label htmlFor="sizes" className="d-block mt-3 ">
                {t("classification")}
              </label>
              <div>
                <AddVariantsAndSkus
                tableSkuSizes={tableSkuSizes}
                settableSkuSizes={settableSkuSizes}
                settingsRes={settingsRes}
                 />
  
               <div style={{overflow:'scroll'}}>
               <SkuTable
               tableSizes={tableSkuSizes}
               deleteElement={deleteSkuElement}
               keys={['title','price','sku','weigth','quantity','image']}
               headers={['#',t("Title name"),t("price"),t("sku"),t("weight"),t("Quantity"),t("pictureLink"),t("delete")]}
                />
              </div>
  
              </div>
              </>
              )
            }
          
          </Tab>


          <Tab
            eventKey="home"
            title={t("product image")}
            style={{ backgroundColor: "white" }}
          >
            {/* <div className="text-form pb-2"> صور للمنتج</div> */}
            <label htmlFor="images" className=" d-block mt-2 ">
              {t("product image")}
            </label>
            <div className="mt-3" id="images">
              <MultiImageInput
                images={images}
                setImages={setImages}
                theme="light"
                max={5}
                // cropConfig={{ crop, ruleOfThirds: true }}
                allowCrop={false}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="video" className="d-block mt-2 ">
                {t("video")}
              </label>
              <input
                id="video"
                onChange={onChangeVideo}
                value={video}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("video")}
              />
            </div>
          </Tab>

          {/* <Tab
            eventKey="dropshipping"
            title={t("DropShipping")}
            style={{ backgroundColor: "white" }}
          >


            <label htmlFor="brand" className=" d-block mt-2 ">
              {t("DropShipping")}
            </label>
            <select
              id="dropshipping"
              value={dropShippingType}
              name="dropshipping"
              onChange={onChangeDropShipping}
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("DropShippings")}</option>
              {dropShippingsResponse
                ? dropShippingsResponse.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}

                    </option>
                  );
                })
                : null}
            </select>


            <label htmlFor="productPriceDropShipping" className=" d-block mt-2 ">
              {t("DropShipping Product Price")}
            </label>
            <input
              id="productPriceDropShipping"
              onChange={onChangeProductPriceDropShipping}
              value={productPriceDropShipping}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("DropShipping Product Price")}
            />


            <label htmlFor="productCodeDropShipping" className=" d-block mt-2 ">
              {t("DropShipping Product Code")}
            </label>
            <input
              id="productCodeDropShipping"
              onChange={onChangeProductCodeDropShipping}
              value={productCodeDropShipping}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("DropShipping Product Code")}
            />

            <Form.Check
              type='checkbox'
              label={t('DropShipping Update Product')}
              onChange={onChangeUpdateProductPriceDropShipping}
              checked={updateProductPriceDropShipping}
              id={`default-checkbox`}
              className="mt-2 d-inline-block"
            />

          </Tab> */}

        </Tabs>
      </Row>
      <Row>
        <Col sm="12" className="d-flex justify-content-end ">
          {check == true ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <button
              onClick={handleSubmit}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("save")}
            </button>
          )}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminEditProduct;
