import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import AdminProducts from "../../Components/Admin/AdminProducts";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import Pagination from "../../Components/Utilities/Pagination";
import ViewProductAdminHook from "../../hook/admin/ViewProductAdminHook";

const AdminProductsPage = () => {
  const [items, pagination, getPage] = ViewProductAdminHook();
  
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  console.log('items');
  console.log(items);
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div>
        <AdminProducts products={items} />
        {pageCount > 1 ? (
          <Pagination pageCount={pageCount} onPress={getPage} />
        ) : null}
      </div>

    </div>
  );
};

export default AdminProductsPage;
