import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EditCouponHook from "../../hook/coupon/EditCouponHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AdminEditCoupon = () => {
  const { id } = useParams();
  const dateRef = useRef();
  const { t } = useTranslation();
  const [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
    coupons,
  ] = EditCouponHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"> {t("Edit Coupon")} </div>
        <Col sm="8">
          <label htmlFor="coponName" className="d-block mt-2">{t("descount copon name")}</label>
          <input
            id="coponName"
            value={couponName}
            onChange={onChangeCouponName}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("descount copon name")}
          />
          <label htmlFor="value" className="d-block mt-2">{t("Discount value")}</label>
          <input
            id="value"
            value={couponValue}
            onChange={onChangeCouponValue}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Discount value")}
          />
          <label htmlFor="type" className="d-block mt-2">{t("Discount value type")}</label>
          <select
            value={couponType}
            onChange={onChangeCouponType}
            name="type"
            id="type"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("Discount value type")}</option>
            <option value="1">{t("value")}</option>
            <option value="2">{t("Percentage")}</option>
          </select>
          <label htmlFor="endDate" className="d-block mt-2">{t("experation date")}  </label>
          <input
            id="endDate"
            ref={dateRef}
            value={couponDate}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("experation date")}
            onChange={onChangeCouponDate}
            onFocus={() => (dateRef.current.type = "date")}
            onBlur={() => (dateRef.current.type = "text")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminEditCoupon;
