import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import VendorSideBar from "../../Components/Vendor/VendorSideBar";
import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";
import AdminProductSalesReport from "../../Components/Admin/AdminProductSalesReport";
import Pagination from "../../Components/Utilities/Pagination";

const VendorReportsPage = () => {

    const [from, setFrom] = useState(() => new Date().toISOString().split('T')[0]);
    const [to, setTo] = useState(() => new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    const [
        items,
        handleSearchDate,
        totalPrice1,
        totalPrice2,
        zeroFlag,
        setZeroFlag,
        settingsRes,
        pagination,
        getPage,
        // loading
    ] = ViewSalesProductAdminHook({ from, to, loading, setLoading });

    if (pagination) var pageCount = pagination;
    else pageCount = 1;

    
    return (
        <Container >
            <Row className='py-3'>
                <Col sm="3" xs="2" md="2">
                    <VendorSideBar />
                </Col>
                <Col sm="9" xs="10" md="10">
                    <AdminProductSalesReport from={from} setFrom={setFrom} to={to} setTo={setTo} loading={loading} setLoading={setLoading} />
                    {pageCount > 0 ? (
                        <Pagination pageCount={pageCount} onPress={getPage} />
                    ) : null}
                </Col>
            </Row>
        </Container>
    )

}

export default VendorReportsPage

