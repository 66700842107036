import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {resetPassword} from "../../redux/actions/authActions"
import notify from "../useNotification";

const ResetPasswordHook = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [value, setValue] = useState("");
  const [loading,setLoading] = useState(true)

  const onChangeNewPassword = (e) => {
    e.persist();
    setNewPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };


const location = useLocation();
const navigate = useNavigate()  
const dispatch = useDispatch()


  useEffect(() => {
    if(location?.state?.email == null){
        // redirct to forget password page if email or phone are not exist
        navigate('/user/forgetpassword')
    }else{

        setValue(location?.state?.email);
    }
  }, [location]);


  const changePassword = async () => {
    if ( newPassword === "" || confirmPassword === "") {
      notify("من فضلك اكمل البيانات", "error");
    } else if (newPassword !== confirmPassword) {
      notify("تاكيد البسورد غير صحيح", "error");
    } else {
      setLoading(true);
      await dispatch(
        resetPassword(value, {
            newPassword: newPassword,
            confirmNewPassword: confirmPassword,
        })
      );
      setLoading(false);
    }
  };

  const resetPasswordResponse = useSelector(state => state.authReducers.resetPassword)

  useEffect(()=>{
    if(loading === false) {
        if(resetPasswordResponse){
            notify("تم تغير كلمة المرور بنجاح", "success");
            setTimeout(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              navigate("/login");
            }, 1500);
        }
    }
  },[resetPasswordResponse,loading])


  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );

  const settingsRes = useSelector((state) => state.settingsReducers.settings);




  return [
    webSiteData,
    settingsRes,
    newPassword,
    confirmPassword,
    value,
    onChangeNewPassword,
    onChangeConfirmPassword,
    changePassword
  ];
};

export default ResetPasswordHook;
