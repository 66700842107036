import React, { useEffect, useState } from "react";
import { createCategory, updateCategory } from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { getAllCategories } from "../../redux/actions/categoryAction";
import { deleteCategory } from "../../redux/actions/categoryAction";
import { useNavigate } from "react-router-dom";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { getSettings } from "../../redux/actions/settingsAcions";



const AddSubCatHook = (catId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  const [show, setShow] = useState(false);
  const [categoryId, setCategryId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setCategryId(id);
    setShow(true);
  };

  const handleDelete = async () => {
    await dispatch(deleteCategory(categoryId));
    notify("تم الحذف بنجاح", "success");
    setShow(false);
    setTimeout(() => {
      navigator("/admin/categories");
      // window.location.reload(true);
    }, 1000);
  };

  const clickHandler = (id) => {
    navigate(`/admin/editCategory/${id}`);
  };

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateCategory(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const categoryRes = useSelector((state)=>state.allCategory.updateCategory)


  const res = useSelector((state) => state.allCategory.category);
  const categories = useSelector((state) => state.subCategory.subCategoryByCat);

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    if (nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");

      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    setIsPress(true);
    await dispatch(createCategory(formData));
    setLoading(false);
  };

  useEffect(() => {
    dispatch(subCategoryByCatId(catId));
  }, []);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);


  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setSelectedFile(null);
      setLoading(true);
      setTimeout(() => setIsPress(false), 1000);

      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      }
      else if (categoryRes){
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(subCategoryByCatId(catId))
        }, 1000);
      }
      else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);
  return [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    categories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes
  ];
};

export default AddSubCatHook;
