import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneCategory } from "../../redux/actions/categoryAction";
// import { getAllProducts, getProductsByCategories, getProductsBySubCategories } from "../../redux/actions/productsAction";
// import { getSettings } from "../../redux/actions/settingsAcions";

const ViewAllProductsBySubCategoryHook = (catId) => {
    // let limit = 8;
    const dispatch = useDispatch();
    // const getProduct = async () => {
    //     await dispatch(
    //         getProductsBySubCategories(catId, limit, 1)
    //     );
    // };
    useEffect(() => {
        // getProduct();
        getById();
        window.scrollTo(0, 0);


    }, []);

    const getById = async () => {
        await dispatch(getOneCategory(catId));
    };

    // const settingsRes = useSelector((state) => state.settingsReducers.settings);

    // const allproduct = useSelector((state) => state.allProduct.productsBySubCategory);

    // const onPress = async (page) => {
    //     await dispatch(
    //         getProductsBySubCategories(catId, limit, page)
    //     );
    // };
    const catById = useSelector((state) => state.allCategory.oneCategory);

    // let items = [];
    // let pagination = [];

    // try {
    //     if (allproduct)
    //         if (allproduct.data) items = allproduct.data;
    //         else items = [];
    // } catch (error) { }
    // try {
    //     if (allproduct)
    //         if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
    //         else pagination = [];
    // } catch (error) { }

    // return [items, pagination, onPress, settingsRes, catById]
     return [catById]
};

export default ViewAllProductsBySubCategoryHook;
