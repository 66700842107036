import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { GENERATE_HYPERPAY_CHECKOUT , ISLOADING_HYPERPAY , GET_HYPERPAY_STATUS } from "../type";


export const generateCheckOut=(body)=>async(dispatch)=>{
    try {
        dispatch({type:ISLOADING_HYPERPAY});
        
        const response= await useInsertData('/api/v1/hyperPay',body);
        dispatch({
            type:GENERATE_HYPERPAY_CHECKOUT,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:GENERATE_HYPERPAY_CHECKOUT,
            payload:error
        });
    }
};

export const getCheckOutStatus=(id)=>async(dispatch)=>{
    try {
        dispatch({type:ISLOADING_HYPERPAY});
        const response=await useGetData(`/api/v1/hyperPay/status?id=${id}`);
        dispatch({
            type:GET_HYPERPAY_STATUS,
            payload:response
        });
    } catch (error) {
        dispatch({
            type:GET_HYPERPAY_STATUS,
            payload:error
        });
    }
}