import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";


const SubTitle = ({ title, btntitle, path }) => {

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const navigate=useNavigate();

  return (
    <div className="d-flex justify-content-between pt-2">
      <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }} >{title}</div>

      {path==='products?isFeatured' ?
      <button
       className="shopping-now" 
       style={{ color: 'black' }}
       onClick={()=>{
        localStorage.setItem('page',1);
        navigate('products?isFeatured=true&page=1',{ state: 'isFeatured' })
      }}>{btntitle}</button> 
      :btntitle ? (
        <button
       className="shopping-now" 
       style={{ color: 'black' }}
       onClick={()=>{
        localStorage.setItem('page',1);
        navigate(`${path}?page=1`)
      }}>{btntitle}</button> 
      ) : null}
    </div>
  );
};

export default SubTitle;
