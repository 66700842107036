import {
  CREATE_REVIEW,
  REVIEWS_BY_PRODUCT_ID,
  DELETE_REVIEW,
  UPDATE_REVIEW,
  GET_ALL_REVIEWS_OF_PRODUCT,
  
} from "../type";
const inital = {
  createReview: [],
  reviewsByProduct: [],
  deleteReview: [],
  updateReview: [],
  loading: true,
  allReviewsOfProduct:[]
};

const reviewReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_REVIEW:
      return {
        ...state,
        createReview: action.payload,
        loading: false,
      };
    case REVIEWS_BY_PRODUCT_ID:
      return {
        ...state,
        reviewsByProduct: action.payload,
        loading: false,
      };
    case DELETE_REVIEW:
      return {
        ...state,
        deleteReview: action.payload,
        loading: false,
      };
    case UPDATE_REVIEW:
      return {
        ...state,
        updateReview: action.payload,
        loading: false,
      };
      case GET_ALL_REVIEWS_OF_PRODUCT:
        return {
          ...state,
          allReviewsOfProduct: action.payload,
         
        };

    default:
      return state;
  }
};

export default reviewReducer;
