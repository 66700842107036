import React, { useEffect, useState } from "react";

import favoff from "../../Images/fav-off.png";
import favon from "../../Images/fav-on.png";
import { useDispatch, useSelector } from "react-redux";

import {
  addToFavorite,
  removeFromFavorite,
} from "../../redux/actions/favoriteActions";
import notify from "../../hook/useNotification";

const ProductCardHook = (item, favProduct) => {
  const dispatch = useDispatch();
  // console.log(favProduct);

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const [favImg, setFavImg] = useState(favoff);
  let Fav = favProduct.some((fItem) => fItem === item._id);
  const [loadingAdd, setLoadingAdd] = useState(true);
  const [loadingRemove, setLoadingRemove] = useState(true);
  const [isFav, setIsFav] = useState(Fav);

  useEffect(() => {
    setIsFav(favProduct.some((fitem) => fitem === item._id));
    
  }, [favProduct]);

  const handleFav = () => {
    if (isFav) {
      removeFromFav();
    } else {
      addToFavData();
    }
  };
  useEffect(() => {
    if (isFav === true) {
      setFavImg(favon);
    } else {
      setFavImg(favoff);
    }
  }, [isFav]);

  const resAdd = useSelector((state) => state.favoriteReducers.addFavorite);
  const resRemove = useSelector(
    (state) => state.favoriteReducers.removeFavorite
  );

  const removeFromFav = async () => {
    setFavImg(favoff);
    setIsFav(false);
    if (user != "") {
      if (user.role == "admin") {
        notify("غير مسموح للادمن بافضه المنتج للمفضلة", "error");
        return;
      }

      setLoadingRemove(true);
      await dispatch(removeFromFavorite({ user: user._id, product: item._id }));
      setLoadingRemove(false);
    } else {
      notify("يجب التسجيل اولا", "error");
    }
  };

  const addToFavData = async () => {
    setFavImg(favon);
    setIsFav(true);
    if (user != "") {
      if (user.role == "admin") {
        notify("غير مسموح للادمن بافضه المنتج للمفضلة", "error");
        return;
      }
      setLoadingAdd(true);
      await dispatch(addToFavorite({ user: user._id, product: item._id }));
      setLoadingAdd(false);
    } else {
      notify("يجب التسجيل اولا", "error");
    }
  };

  useEffect(() => {
    if (loadingRemove === false) {
      if (resRemove && resRemove.success === true) {
        notify("تم حذف المنتج من المفضلة", "warn");
      }
    }
  }, [loadingRemove]);

  useEffect(() => {
    if (loadingAdd === false) {
      if (resAdd && resAdd.success === true) {
        notify("تم اضافة المنتج من المفضلة", "success");
      }
    }
  }, [loadingAdd]);

  return [favImg, handleFav];
};

export default ProductCardHook;
