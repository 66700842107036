import { GET_ALL_BRAND, GET_ERROR, CREATE_BRAND, BRAND_BY_ID, UPDATE_BRAND, GET_ALL_BRANDS } from "../type";
const inital = {
  allBrands:[],
  brand: [],
  brandById: [],
  updateBrand: [],
  loading: true,
};

const brandReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_BRAND:
      return {
        ...state,
        brand: action.payload,
        loading: false,
      };
    case CREATE_BRAND:
      return {
        brand: action.payload,
        loading: false,
      };
      case GET_ALL_BRANDS:
        return {
          allBrands: action.payload,
          loading: false,
        };
    case BRAND_BY_ID:
      return {
        brandById: action.payload,
        loading: false,
      };
    case UPDATE_BRAND:
      return {
        ...state,
        updateBrand: action.payload,
        loading: false,
      };

    case GET_ERROR:
      return {
        brand: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default brandReducer;
