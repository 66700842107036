import { ADD_TRANSACTION, GET_ONE_TRANSACTION, UPDATE_ONE_TRANSACTION, USER_TRANSACTION } from "../type";

const inital = {
  addTransaction: [],
  allUserTransaction: [],
  oneTransaction:[],
  updateTransaction:[]
};

const walletReducer = (state = inital, action) => {
  switch (action.type) {
    case ADD_TRANSACTION:
      return {
        ...state,
        addTransaction: action.payload,
      };
    case USER_TRANSACTION:
      return {
        ...state,
        allUserTransaction: action.payload,
      };
      case GET_ONE_TRANSACTION:
        return {
          ...state,
          oneTransaction: action.payload,
        };
        case UPDATE_ONE_TRANSACTION:
          return {
            ...state,
            updateTransaction: action.payload,
          };


    default:
      return state;
  }
};

export default walletReducer;
