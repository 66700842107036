

import React from "react";
import editicon from "../../Images/edit.png";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Cookies from "js-cookie";
const AdminShipingCard = ({ shippingMethod }) => {
  return (
    <div className="user-address-card-shipping-2 my-3 px-2">


      <Row className="d-flex justify-content-between  ">
        <Col xs="6">

          <div className="p-2">{t("shiping method name")}   : {Cookies.get("i18next") === "ar" ? shippingMethod.nameAr : shippingMethod.nameEn}`</div>
          <div className="p-2">  {t("the status")} : {shippingMethod.status ? t("active") : t("not active")}</div>
          <div className="p-2">  {t("عدد الوزن")}   : {shippingMethod.wieghtNumber}</div>
          <div className="p-2">  {t("تكلفة الوزن")}   : {shippingMethod.wieghtCost}</div>
          <div className="p-2">  {t("تكلفة الوزن الاضافيه")}   : {shippingMethod.wieghtExtraCost}</div>


        </Col>

        <Col xs="6" className="d-flex d-flex justify-content-end">
          <div className="d-flex p-2">
            <Link
              to={`/admin/editShippingMethod/${shippingMethod._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex mx-2">
                <img
                  alt=""
                  className="ms-1 mt-2"
                  src={editicon}
                  height="17px"
                  width="15px"
                />
                <p className="item-delete-edit"> {t("Modify")}</p>
              </div>
            </Link>
          </div>
        </Col>
      </Row>

    </div>
  );
}

export default AdminShipingCard


