import React,{useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminOrders from "../../Components/Admin/AdminOrders";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import Pagination from "../../Components/Utilities/Pagination";
import AdminAllOrdersHook from "../../hook/admin/AdminAllOrdersHook";
import { useSearchParams } from "react-router-dom";

const AdminOrdersPage = () => {

  const[searchParams,setSearchParams]=useSearchParams();

  const [from, setFrom] = useState( 
  ()=> searchParams.get('from') ? searchParams.get('from') :  new Date().toISOString().split('T')[0]
    );
  const [to, setTo] = useState(
    ()=> searchParams.get('to') ? searchParams.get('to') :  new Date().toISOString().split('T')[0]
  );
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  //console.log("from",fromDate);

  const [
    username,
    result,
    orderData,
    noPages,
    onPress,
    webSiteData,
    settingsRes,
    handleSearchDate,
    couponSearch,
    onChangeCouponSearch,
    handleSearchCoupon,
    handleRefresh,
    loadingDate,
    pagination,
    getPage
  ] = AdminAllOrdersHook({from,to,loadingSpinner, setLoadingSpinner});

  //fromDate,to,loadingSpinner,setLoadingSpinner

  if (pagination) var pageCount = pagination;
    else pageCount = 1;

    //console.log('pagination',pagination);
    
    //from,to,loadingSpinner,setLoadingSpinner

  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminOrders from={from} setFrom={setFrom} to={to} setTo={setTo} loadingSpinner={loadingSpinner} setLoadingSpinner={setLoadingSpinner}  />
        {pageCount > 0 ? (
          <Pagination pageCount={pageCount} onPress={getPage} />
        ) : null}
      </div>
    </div>
  );
};

export default AdminOrdersPage;
