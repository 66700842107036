import {
  GET_ALL_DROPSHIPPINGS,
  GET_ONE_DROP_SHIPPING,
  GET_ONE_DROP_SHIPPING_BY_KEY,
  UPDATE_DROPSHIPPING,
} from "../type";

const inital = {
  allDropShippings: [],
  updateDropShippng: [],
  oneDropShipping: [],
  oneDropShippingByKey:{}
};

const dropShippingReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_DROPSHIPPINGS:
      return {
        ...state,
        allDropShippings: action.payload,
      };
    case UPDATE_DROPSHIPPING:
      return {
        ...state,
        updateDropShippng: action.payload,
      };
    case GET_ONE_DROP_SHIPPING:
      return {
        ...state,
        oneDropShipping: action.payload,
      };
    case GET_ONE_DROP_SHIPPING_BY_KEY:
      return{
        ...state,
        oneDropShippingByKey:action.payload
      };

    default:
      return state;
  }
};

export default dropShippingReducer;
