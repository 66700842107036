import { AdminDropShippings } from "../../Components/Admin/AdminDropShippings"
import SideBar from "../../Components/Admin/SideBar"

const AdminDropShippingsPage = () => {

    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
         <AdminDropShippings/>
        </div>
      </div>
    )

}

export default AdminDropShippingsPage