import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AddCatHook from "../../hook/category/AddCatHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAddCategory = () => {
  const { t } = useTranslation();
  const [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    categories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes
  ] = AddCatHook();
  return (
    <div>

      <Helmet>
        <title>{`${t("add categoryy")}- ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>

      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("add category")}</div>
        <Col sm="8">
          <div className="text-form pb-2">{t("category image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="catAr" className="d-block mt-2 ">{t("category name in arabic")} </label>
          <input
            id="catAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("category name in arabic")}
          />
          <label htmlFor="catEn" className="d-block mt-2 ">{t("category name in english")}</label>

          <input
            id="catEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("category name in english")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={handleSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("save")}
          </button>
        </Col>
      </Row>
      {isPress ? (
        loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <h4>{t("done")}</h4>
        )
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default AdminAddCategory;
