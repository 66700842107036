import React from "react";
import AdminViewslider from "../../Components/Admin/AdminViewslider";
import SideBar from "../../Components/Admin/SideBar";

const AdminViewsliderPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminViewslider />
            </div>
        </div>
    );
};
export default AdminViewsliderPage;
